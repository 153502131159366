import { Button, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { Font } from '~/components/Font'
import { MapInput } from '~/components/MapInput'
import { Stack } from '~/components/Stack'
import { HelpIcon } from '~/legacy/components'
import { evaluate } from '~/support/evaluate'
import { useEnterField } from '~/support/useEnterField'
import { useFetch } from '~/support/useFetch'
import { useDebounce } from '~/support/useDebounce'
import { useChanges } from '~/support/useChange'

const noop = () => {}

const parsePosition = (geo) => {
  return { lat: geo.latitude, lng: geo.longitude }
}

export const EditLocationForm = ({ building, onChange = noop, ...props }) => {
  const position = parsePosition(building.geo)
  const fetch = useDebounce(300, useFetch(), [])

  const [original] = useState(building)
  const [address, setAddress] = useState(building)
  const [center, setCenter] = useState(position)
  const [location, setLocation] = useState(position)
  const update = useDebounce(300, onChange, [location])

  useChanges(location, ({ lat, lng }) => {
    const geo = { latitude: lat, longitude: lng }
    fetch(`/api/building/${building.id}`, { body: geo })
    update({ geo, latitude: String(lat), longitude: String(lng) })
  })

  const syncAddress = (data) => {
    fetch(`/api/building/${building.id}`, { body: data })
    setAddress({ ...address, ...data })
    onChange?.(data)
  }

  const resetAddress = () =>
    syncAddress({
      address: original.address,
      city: original.city,
      state: original.state,
      zipcode: original.zipcode,
    })

  const inputAddress = (key, label) => {
    const value = address[key] || ''

    return {
      label,
      value,
      variant: 'outlined',
      required: true,
      error: value.length === 0,
      helperText: value.length > 0 ? null : `${label} is required.`,
      onChange: (event) => {
        const value = String(event.target.value)
        syncAddress({ [key]: value })
      },
      onBlur: (event) => {
        const value = String(event.target.value)
        if (!value) syncAddress({ [key]: original[key] })
      },
    }
  }

  const lat = useEnterField(location.lat, (value) => {
    const lat = evaluate(value)
    if (lat != null) setCenter({ ...location, lat })
  })

  const lng = useEnterField(location.lng, (value) => {
    const lng = evaluate(value)
    if (lng != null) setCenter({ ...location, lng })
  })

  return (
    <Stack gap={24} {...props}>
      <Stack gap={16}>
        <Stack align="center" gap={4} inline>
          <Font format="14/14/600" grow>
            Display Address
          </Font>
          <Button onClick={resetAddress}>
            <Font format="14/14/600" color="theme.palette.primary.main">
              Reset
            </Font>
          </Button>
          <HelpIcon />
        </Stack>
        <Stack gap={24}>
          <TextField {...inputAddress('address', 'Display Address')} />
          <TextField {...inputAddress('city', 'Display City')} />
          <TextField {...inputAddress('state', 'Display State/Province')} />
          <TextField {...inputAddress('zipcode', 'Display ZIP')} />
        </Stack>
      </Stack>
      <Stack gap={16}>
        <Stack align="center" gap={4} inline>
          <Font format="14/14/600" grow>
            Map Pin Position
          </Font>
          <Button onClick={() => setCenter(parsePosition(original.geo))}>
            <Font format="14/14/600" color="theme.palette.primary.main">
              Reset
            </Font>
          </Button>
          <HelpIcon />
        </Stack>
        <Stack gap={24}>
          <MapInput center={center} value={location} onChange={setLocation} />
          <Stack gap={24} inline>
            <TextField
              {...lat}
              variant="outlined"
              label="Latitude"
              fullWidth
              required
            />
            <TextField
              {...lng}
              variant="outlined"
              label="Longitude"
              fullWidth
              required
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
