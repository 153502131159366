/* eslint-disable import/no-extraneous-dependencies */
// @ts-check
import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  LEASE_ADMIN,
  LEASE_DASHBOARD,
  LEASE_LOGIN,
  LEASE_MAGIC_LINK,
  LEASE_MISSING_SUBDOMAIN,
  LEASE_PROJECTS,
  LEASE_PROJECT_BY_ID,
  LEASE_PROJECT_WITH_ACTION,
  LEASE_ROOT,
  LEASE_SETTINGS,
  LEASE_SURVEY_COMPARE_LISTINGS,
  LEASE_SURVEY_COMPARE_SPACES,
  LEASE_SURVEY_EXPORT,
  LEASE_SURVEY_VIEW_LISTING,
  LEASE_FEEDBACK,
  LEASE_ZENDESK,
  PROJECT_VIEW_BY_ID,
  BUILDING_DETAIL_PAGE,
  PROJECT_DETAIL,

  // legacy
  LEASE_LISTINGS,
  LEASE_VIEW_LISTINGS,
  LEASE_VIEW_LISTINGS_BY_ID,
  BROKER_HOME,
  LEASE_RESET_PASSWORD,
  LEASE_CREATE_PASSWORD,
  LEASE_PROJECT_BULK_EDIT,
} from '~/legacy/consts'
import MissingSubdomain from './404/MissingSubdomain'
import Account from './Account/Account'
import Admin from './Admin'
import Brand from './Brand/Brand'
import CompareSurveyListings from './CompareListings/CompareSurveyListings'
import Feedback from './Feedback'
import ViewListing from './Listings/ViewListing/ViewListing'
import Login from './Login/Login'
import MagicLink from './MagicLink/MagicLink'
import BrokerSurvey from './Surveys/Survey/BrokerSurvey'
import ExportSurvey from './Surveys/Survey/Export/ExportSurvey'
import Surveys from './Surveys/Surveys'
import Zendesk from './Zendesk'
import ProjectDetailPage from './project/ProjectDetailPage'
import BuildingDetailPage from './Buildings/BuildingDetailPage'
import BrokerHome from './Broker/BrokerHome'
import ResetPassword from './Login/ResetPassword'
import CreatePassword from './Login/CreatePassword'
import { Project } from './Projects/Project'
import BulkEditDatabase from '~/legacy/pages/Databases/BulkEditDatabase'
import BulkEditSurvey from '~/legacy/pages/Surveys/Survey/BulkEditSurvey'

export { Surveys }

const routes = {
  home: {
    path: LEASE_ROOT,
    isPublic: true,
    component: Login,
    exact: true,
    allowMobile: true,
    trackingPageViewName: 'View Homepage',
  },
  dashboard: {
    path: LEASE_DASHBOARD,
    component: Surveys,
    exact: true,
    allowMobile: true,
    trackingPageViewName: 'View Dashboard',
  },
  viewSurvey: {
    path: LEASE_PROJECT_BY_ID,
    component: BrokerSurvey,
    allowAnonymous: true,
    exact: true,
    allowMobile: true,
    trackingPageViewName: 'View Survey',
    hideScroll: true,
  },
  bulkEditSurvey: {
    path: LEASE_PROJECT_BULK_EDIT,
    component: BulkEditSurvey,
    allowAnonymous: false,
    exact: true,
    allowMobile: true,
    trackingPageViewName: 'Bulk Edit Survey',
    hideScroll: true,
  },
  bulkEditDatabase: {
    path: '/databases/:id',
    component: BulkEditDatabase,
    allowAnonymous: false,
    exact: true,
    allowMobile: false,
    trackingPageViewName: 'View Database',
    hideScroll: true,
  },
  exportSurvey: {
    path: LEASE_SURVEY_EXPORT,
    component: (props) => <ExportSurvey {...props} />,
    exact: true,
    trackingPageViewName: 'Export Survey',
    hideScroll: true,
  },
  project: {
    path: LEASE_PROJECT_WITH_ACTION,
    component: BrokerSurvey,
    exact: true,
  },
  projects: {
    path: LEASE_PROJECTS,
    component: Surveys,
    exact: true,
    trackingPageViewName: 'View Surveys',
  },
  viewListing: {
    path: LEASE_VIEW_LISTINGS_BY_ID,
    component: ViewListing,
    exact: false,
    allowMobile: true,
    trackingPageViewName: 'View Listing',
  },
  viewSurveyListing: {
    path: LEASE_SURVEY_VIEW_LISTING,
    component: ViewListing,
    allowAnonymous: true,
    exact: false,
    allowMobile: true,
    trackingPageViewName: 'View Listing',
  },
  compareListingsLegacy: {
    path: LEASE_SURVEY_COMPARE_LISTINGS,
    component: (props) => <CompareSurveyListings {...props} />,
    allowAnonymous: true,
    allowMobile: true,
  },
  compareListings: {
    path: LEASE_SURVEY_COMPARE_SPACES,
    component: (props) => <CompareSurveyListings {...props} />,
    allowAnonymous: true,
    allowMobile: true,
  },
  settings: {
    path: LEASE_SETTINGS,
    component: Account,
    exact: true,
    trackingPageViewName: 'View Account',
  },
  login: {
    path: LEASE_LOGIN,
    component: Login,
    isPublic: true,
    exact: true,
    allowMobile: true,
    trackingPageViewName: 'View Login',
  },
  resetPassword: {
    path: LEASE_RESET_PASSWORD,
    component: ResetPassword,
    exact: true,
  },
  createPassword: {
    path: LEASE_CREATE_PASSWORD,
    component: CreatePassword,
    exact: true,
  },
  magicLink: {
    path: LEASE_MAGIC_LINK,
    component: MagicLink,
    isPublic: true,
    isRedirect: true,
    exact: true,
    allowMobile: true,
    trackingPageViewName: 'View Magic Link',
  },
  missingSubdomain: {
    path: LEASE_MISSING_SUBDOMAIN,
    component: MissingSubdomain,
    isPublic: true,
    exact: true,
    allowMobile: true,
    skipLoginRedirect: true,
    trackingPageViewName: 'View Missing Subdomain',
  },
  feedback: {
    path: LEASE_FEEDBACK,
    component: Feedback,
    exact: true,
  },
  zendesk: {
    path: LEASE_ZENDESK,
    component: Zendesk,
    exact: true,
    isPublic: true,
    isRedirect: true,
  },
  admin: {
    path: LEASE_ADMIN,
    component: Admin,
    exact: true,
    isPublic: false,
    requireAdmin: true,
    trackingPageViewName: 'Admin',
  },

  // legacy redirects
  listings_legacy: {
    path: LEASE_LISTINGS,
    component: <Redirect to={LEASE_DASHBOARD} />,
    exact: true,
  },
  listings_view_legacy: {
    path: LEASE_VIEW_LISTINGS,
    component: <Redirect to={LEASE_DASHBOARD} />,
    exact: true,
  },
  viewProject: {
    path: PROJECT_VIEW_BY_ID,
    component: ProjectDetailPage,
    allowAnonymous: true,
    exact: true,
    allowMobile: true,
    trackingPageViewName: 'View Project',
  },
  buildingDetailPage: {
    path: BUILDING_DETAIL_PAGE,
    component: BuildingDetailPage,
    allowAnonymous: true,
    allowMobile: true,
    trackingPageViewName: 'Building Detail Page',
  },
  brokerHome: {
    path: BROKER_HOME,
    component: BrokerHome,
    isPublic: false,
    exact: true,
  },
  projectDetail: {
    path: PROJECT_DETAIL,
    component: Project,
    isPublic: false,
    exact: true,
  },
}

if (process.env.DEV) {
  routes.brand = {
    path: '/brand',
    component: Brand,
    exact: true,
  }
}

export default routes
