// @ts-check
import React, { useMemo, Fragment } from 'react'
import {
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import clsx from 'clsx'
import groupBy from 'lodash/groupBy'
import { Typography } from '~/legacy/components'
import { PlusIcon } from '~/legacy/components/svgs'
import {
  TableHeaderCell,
  StringCell,
  cellComponentMap,
} from './table-components'

export function SpacesTab({
  spaceColumns,
  surveyBuildings,
  surveyListings,
  listingValues,
  onValueChange,
  onAddSpace,
  showFilters,
}) {
  const data = useMemo(() => {
    const buildingsMap = groupBy(surveyBuildings, 'building.id')
    const valuesByListing = groupBy(listingValues, 'listing_id')
    const listingsByBuilding = groupBy(surveyListings, 'listing.building.id')

    return (
      Object.keys(buildingsMap)
        // Make sure the keys are sorted by the `surveyBuilding` order
        // Since the JS Object's default order does not match our desired order
        .sort((a, b) => buildingsMap[a][0].order - buildingsMap[b][0].order)
        .map((buildingId) => {
          const surveyBuilding = buildingsMap[buildingId][0]
          const { building } = surveyBuilding
          const listings = listingsByBuilding[buildingId] || []

          const mappedListings = listings.map(({ listing }) => {
            const values = []

            spaceColumns.forEach((column) => {
              const modelValue = column.reserved
                ? listing[column.modelName]
                : null
              const customFieldValue = !column.reserved
                ? valuesByListing[listing.id]?.find(
                    (item) => item.custom_field.id === column.field.id
                  )
                : null

              values.push({
                id: column.reserved ? listing.id : customFieldValue?.id,
                value: column.reserved ? modelValue : customFieldValue?.value,
              })
            })

            return {
              ...listing,
              values,
            }
          })

          return { ...building, listings: mappedListings, surveyBuilding }
        })
    )
  }, [surveyBuildings, surveyListings, spaceColumns, listingValues])

  return (
    <>
      <div className={clsx('w-full ml-8', showFilters && 'ml-[348px]')}>
        <div className="absolute top-[122px] left-0 bottom-4 w-8 bg-white z-10" />
        <TableContainer className="flex-none overflow-visible w-full h-full">
          <Table size="small" className="border-separate pr-8">
            <TableHead className="sticky top-[122px] z-[3]">
              <TableRow>
                {spaceColumns.map((header, arrayIndex) => {
                  return (
                    <TableHeaderCell
                      key={header.index}
                      allColumns={spaceColumns}
                      index={arrayIndex}
                      header={header}
                      filtersVisible={showFilters}
                    />
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((building) => {
                return (
                  <Fragment key={building.id}>
                    <TableRow>
                      <TableCell
                        className={clsx(
                          'h-[84px] pt-[36px] pb-5 pl-0 whitespace-nowrap border-0',
                          'sticky z-[1] bg-white border-r-2',
                          showFilters ? 'first:left-[348px]' : 'left-8'
                        )}
                      >
                        <Typography
                          variant="boldText"
                          className="font-semibold text-sm leading-7"
                        >
                          {building.address}
                        </Typography>
                      </TableCell>
                      <TableCell
                        colSpan={spaceColumns.length - 1}
                        className="border-0 bg-white"
                      />
                    </TableRow>

                    {building.listings.map((listing, listingIndex) => (
                      <TableRow key={listing.id} data-row-id={listing.id}>
                        {listing.values.map((cell, cellIndex) => {
                          const listingCount = building.listings.length

                          const header = spaceColumns[cellIndex]
                          const DisplayCell =
                            cellComponentMap[header.fieldDataType.id] ??
                            StringCell

                          const isSpaceNotes =
                            header.displayName === 'Space Notes'

                          return (
                            <TableCell
                              key={`${header.displayName}-${listing.id}`}
                              data-value-id={`${header.displayName}-${listing.id}`}
                              className={clsx(
                                'w-[100px] py-3 px-4 h-20 bg-white',
                                listingIndex === listingCount - 1 && 'border-b',
                                'border-0 border-solid border-[#E0E0E0] border-l border-t',
                                'first:sticky first:z-[1] first:border-r-2 last:border-r',
                                listingIndex === 0 &&
                                  'first:rounded-tl last:rounded-tr',
                                isSpaceNotes && 'max-w-[344px]',
                                cellIndex === 1 && 'border-l-0',
                                showFilters
                                  ? 'first:left-[348px]'
                                  : 'first:left-8'
                              )}
                            >
                              <div className="max-h-14">
                                <DisplayCell
                                  cell={cell || {}}
                                  header={header}
                                  onChange={(_, value) => {
                                    onValueChange({
                                      entityId: listing.id,
                                      header,
                                      cell,
                                      value,
                                    })
                                  }}
                                />
                              </div>
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    ))}

                    <TableRow>
                      <TableCell
                        className={clsx(
                          'w-[100px] py-3 px-4 h-20',
                          'border-0 border-dotted border-[#E0E0E0] first:border-l first:rounded-bl border-b last:border-r last:rounded-br',
                          'sticky z-[1] bg-white',
                          !building.listings.length &&
                            'border-t border-dotted #E0E0E0',
                          showFilters ? 'first:left-[348px]' : 'left-8'
                        )}
                      >
                        <ButtonBase
                          disableRipple
                          onClick={() => {
                            onAddSpace(building.surveyBuilding.id)
                          }}
                        >
                          <Typography
                            variant="boldText"
                            color="primary"
                            className="flex items-center gap-[18px]"
                          >
                            <PlusIcon />
                            Add Space
                          </Typography>
                        </ButtonBase>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          'border-0 border-dotted border-[#E0E0E0] first:border-l first:rounded-bl border-b last:border-r last:rounded-br',
                          !building.listings.length &&
                            'border-t border-dotted #E0E0E0'
                        )}
                        colSpan={spaceColumns.length - 1}
                      />
                    </TableRow>
                  </Fragment>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
}
