import { Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import CSVReader from 'react-csv-reader'
import {
  BULK_IMPORT_CONSTANTS,
  BULK_IMPORT_HELPERS,
  uploadFile,
} from '~/legacy/utils'

const useStyles = makeStyles({
  csvReaderInput: {
    display: 'none',
  },
})

const BulkImportCsvReader = ({
  surveyId,
  linkRef,
  setBackdropOpen,
  setUploadedCsvFile,
  setLoadedListingsFromCsv,
  setRawCsvHeaders,
  setBulkImportFieldMatchModalOpen,
}) => {
  const classes = useStyles()

  return (
    <CSVReader
      label={
        <Link style={{ display: 'none' }} ref={linkRef} color="primary">
          Import CSV
        </Link>
      }
      cssInputClass={classes.csvReaderInput}
      onFileLoaded={(results, fileInfo, originalFile) => {
        setBackdropOpen(true)

        // Upload the CSV file to S3 (via webapp)
        uploadFile(
          originalFile,
          'bulk_import_csv_file',
          { survey_id: surveyId },
          true
        ).then((result) => {
          if (result && result.data && result.data.file) {
            setUploadedCsvFile(result.data.file)
          }
        })

        // Strip out any rows with no data
        const nonEmptyRowResults = results.filter((result) =>
          Object.values(result).find(
            (columnData) => columnData !== undefined && columnData !== null
          )
        )

        // Start the bulk import of the file
        BULK_IMPORT_HELPERS.onCsvFileLoaded(
          nonEmptyRowResults,
          setLoadedListingsFromCsv,
          setRawCsvHeaders,
          setBulkImportFieldMatchModalOpen
        )
      }}
      parserOptions={BULK_IMPORT_CONSTANTS.CSV_UPLOAD_PARSER_OPTIONS}
    />
  )
}

export default BulkImportCsvReader
