// @ts-check
import debounce from 'lodash/debounce'
import { useCallback, useMemo } from 'react'
import {
  StringParam,
  JsonParam,
  useQueryParams,
  withDefault,
  createEnumParam,
} from 'use-query-params'

const TabParam = createEnumParam(['buildings', 'spaces'])
const SortOrderParam = createEnumParam(['asc', 'desc'])

const SEARCH_SCHEMA = {
  // Standard building filters
  fullAddressMatches: withDefault(StringParam, ''),
  buildingName: withDefault(StringParam, ''),
  buildingDescription: withDefault(StringParam, ''),

  // Standard space filters
  spaceName: withDefault(StringParam, ''),
  spaceNotes: withDefault(StringParam, ''),

  // Custom field filters
  buildingCustom: withDefault(JsonParam, {}),
  spaceCustom: withDefault(JsonParam, {}),

  // Sort params
  sortColumn: StringParam,
  sortOrder: SortOrderParam,

  tab: withDefault(TabParam, 'buildings'),
}

export const useSearch = () => {
  const [query, setQuery] = useQueryParams(SEARCH_SCHEMA)
  const {
    buildingCustom,
    spaceCustom,
    sortColumn,
    sortOrder,
    fullAddressMatches,
    buildingName,
    spaceName,
    spaceNotes,
    tab,
  } = query

  const setCleanedQuery = useCallback(
    (newQuery, updateType = 'pushIn') => {
      setQuery(
        getCleanedFilters({
          ...newQuery,
        }),
        updateType
      )
    },
    [setQuery]
  )

  const debouncedSetQuery = useCallback(
    debounce((query) => setCleanedQuery(query), 300),
    []
  )

  const filterCount = useMemo(() => getFilterCount(query), [query])

  return {
    query,
    tab,
    buildingName,
    fullAddressMatches,
    buildingCustom,
    spaceCustom,
    spaceName,
    spaceNotes,
    sortColumn,
    sortOrder,
    setQuery: setCleanedQuery,
    debouncedSetQuery,
    filterCount,
  }
}

const getCleanedFilters = (newFilters) => {
  return Object.entries(newFilters).reduce((acc, [key, value]) => {
    if (key === 'buildingCustom' || key === 'spaceCustom') {
      const newValue = { ...value }

      // Remove empty values from url
      Object.entries(value).forEach(([fieldId, value]) => {
        if (Array.isArray(value) && value.length === 0) {
          delete newValue[fieldId]
          return
        }

        if (value === '-') {
          delete newValue[fieldId]
          return
        }

        // Date filter of the form { start: '2022-01-01', end: '2022-12-31' }
        if (typeof value === 'object' && !Array.isArray(value)) {
          if (!value.start) {
            delete newValue[fieldId].start
          }

          if (!value.end) {
            delete newValue[fieldId].end
          }

          if (!value.start && !value.end) {
            delete newValue[fieldId]
          }
        }
      })

      acc[key] = newValue
    } else {
      acc[key] = value
    }

    return acc
  }, {})
}

const getFilterCount = (queryState) => {
  const {
    buildingCustom,
    spaceCustom,
    buildingName,
    fullAddressMatches,
    spaceName,
    spaceNotes,
    buildingDescription,
  } = queryState

  const buildingFiltersCount =
    Object.keys(buildingCustom).length +
    [buildingName, fullAddressMatches, buildingDescription].filter(Boolean)
      .length

  const spaceFiltersCount =
    Object.keys(spaceCustom).length +
    [spaceName, spaceNotes].filter(Boolean).length

  return buildingFiltersCount + spaceFiltersCount
}
