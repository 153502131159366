// @ts-check
import { useCallback } from 'react'
import groupBy from 'lodash/groupBy'
import useSWR from 'swr'
import { useSelector } from 'react-redux'
import Api from 'rest-fetcher-redux'
import { parseISO, compareAsc } from 'date-fns'
import { useSurveyCustomFieldsSelector } from '~/legacy/utils/hooks/useSurveyCustomFieldsSelector'
import { useBuildingCustomFields } from './use-building-custom-fields'
import { useListingCustomFields } from './use-listing-custom-fields'
import { useRedirectNoPermission } from './redirectNoPermissions'
import { BULK_IMPORT_CONSTANTS } from '~/legacy/utils'
import { useSearch } from '~/legacy/components/search-bar/use-search'

const isBadAuthResponseMessage = (responseMessage) => {
  return [
    'You do not have permission to perform this action.',
    'Authentication credentials were not provided.',
  ].includes(responseMessage)
}

/**
 *
 * @param {Object} options
 * @param {Number} [options.surveyId]
 * @param {'survey' | 'database'} [options.kind]
 * @param {Boolean} [options.enabled]
 */
export const useBulkEdit = ({ surveyId, kind = 'survey', enabled = true }) => {
  // Filters and sort params
  const {
    buildingName,
    fullAddressMatches,
    buildingCustom,
    spaceCustom,
    spaceName,
    spaceNotes,
    sortColumn,
    sortOrder,
  } = useSearch()

  // SWR
  const {
    survey,
    buildingCustomFields,
    listingCustomFields,
    isLoading: isCustomFieldsLoading,
    mutateCreateCustomField: createCustomField,
    mutateChangeCustomFieldLabel: renameField,
    mutateDeleteCustomField: deleteField,
    mutateChangeCustomFieldType: changeFieldDataType,
    mutateChangeCustomFieldTemplate: changeFieldType,
    mutateSetCustomFieldOrder: setFieldsOrder,
  } = useSurveyCustomFieldsSelector({
    surveyId,
    kind,
    enabled,
  })

  const {
    buildingValues,
    isBuildingValuesLoading,
    buildingColumns,
    handleBuildingValueChange,
    refreshBuildingValues,
  } = useBuildingCustomFields({
    surveyId,
    kind,
    buildingCustomFields,
  })

  const {
    listingValues,
    isListingValuesLoading,
    spaceColumns,
    handleAddSpace,
    handleListingValueChange,
    refreshListingValues,
  } = useListingCustomFields({ surveyId, kind, listingCustomFields })

  const {
    surveyBuildings,
    surveyListings,
    mutate: refreshAllListings,
    isLoading: allListingsLoading,
  } = useAllListings({
    surveyId,
    kind,
    enabled,
  })

  const anyLoading =
    allListingsLoading ||
    isBuildingValuesLoading ||
    isListingValuesLoading ||
    isCustomFieldsLoading

  const getFilteredBuildings = useCallback(() => {
    const filterKeys = Object.entries(buildingCustom)
    if (!filterKeys.length && !buildingName && !fullAddressMatches) {
      return surveyBuildings
    }

    const groupedBuildingValues = groupBy(buildingValues, 'building_id')

    return surveyBuildings.filter((sb) => {
      const buildingId = sb.building.id
      const valuesForBuilding = groupedBuildingValues[buildingId] || []

      // FIXME: This is pretty basic. Maybe use Fuse.js or move this 2 filters to a global search
      const buildingNameFilter =
        !buildingName ||
        (sb.building.name?.toLowerCase() || '').includes(
          buildingName.toLowerCase()
        )
      const fullAddressMatchesFilter =
        !fullAddressMatches ||
        ['address', 'address2', 'city', 'state', 'zipcode']
          .map((key) => sb.building[key]?.toLowerCase() || '')
          .join(' ')
          .includes(fullAddressMatches.toLowerCase())

      // Check if this building satisfies all filters
      return (
        buildingNameFilter &&
        fullAddressMatchesFilter &&
        filterKeys.every(([fieldId, allowedValues]) => {
          // Find a matching value for this field ID in the building's values
          return valuesForBuilding.some((bv) => {
            const isCurrentFilter = bv.custom_field_id === Number(fieldId)
            const filterFunction = getFilterFunctionByType(
              bv.custom_field.data_type
            )

            return isCurrentFilter && filterFunction(allowedValues, bv.value)
          })
        })
      )
    })
  }, [
    surveyBuildings,
    buildingValues,
    buildingCustom,
    buildingName,
    fullAddressMatches,
  ])

  const getSortedBuildings = useCallback(
    (filteredBuildings) => {
      const newSortedBuildings = [...filteredBuildings]
      if (!sortColumn) {
        return newSortedBuildings
      }

      const field = buildingCustomFields.find(
        (field) => field.id === Number(sortColumn)
      )

      if (!field) {
        const sortFunction = getSortFunctionByType(
          sortColumn === BULK_IMPORT_CONSTANTS.FIELDS.AMENITIES.modelFieldName
            ? BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.LIST.id
            : BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.id
        )

        return newSortedBuildings.sort((a, b) => {
          const aValue = a.building[sortColumn]
          const bValue = b.building[sortColumn]

          return sortOrder === 'desc'
            ? sortFunction(bValue, aValue)
            : sortFunction(aValue, bValue)
        })
      }

      const sortFunction = getSortFunctionByType(field.data_type)
      const valuesByField =
        groupBy(buildingValues, 'custom_field_id')[field.id] || []

      const orderMap = valuesByField
        .sort((a, b) => {
          const aValue = a.value
          const bValue = b.value

          return sortOrder === 'desc'
            ? sortFunction(bValue, aValue)
            : sortFunction(aValue, bValue)
        })
        .reduce((map, item, index) => {
          map[item.building_id] = index
          return map
        }, {})

      return newSortedBuildings.sort((a, b) => {
        return orderMap[a.building.id] - orderMap[b.building.id]
      })
    },
    [buildingCustomFields, buildingValues, sortColumn, sortOrder]
  )

  const getFilteredListings = useCallback(() => {
    const filterKeys = Object.entries(spaceCustom)
    if (!filterKeys.length && !spaceName && !spaceNotes) {
      return surveyListings
    }

    const groupedListingValues = groupBy(listingValues, 'listing_id')

    return surveyListings.filter((sl) => {
      const listingId = sl.listing.id
      const valuesForListing = groupedListingValues[listingId] || []

      // Check if this building satisfies all filters
      return Object.entries(spaceCustom).every(([fieldId, allowedValues]) => {
        // Find a matching value for this field ID in the building's values
        return valuesForListing.some((lv) => {
          const isCurrentFilter = lv.custom_field_id === Number(fieldId)
          const filterFunction = getFilterFunctionByType(
            lv.custom_field.data_type
          )

          return isCurrentFilter && filterFunction(allowedValues, lv.value)
        })
      })
    })
  }, [surveyListings, listingValues, spaceCustom, spaceName, spaceNotes])

  return {
    survey,
    isLoading: anyLoading,
    surveyId,
    kind,
    buildingCustomFields,
    listingCustomFields,
    buildingValues,
    listingValues,
    buildingColumns,
    spaceColumns,
    surveyBuildings,
    surveyListings,
    createCustomField,
    renameField,
    deleteField,
    changeFieldDataType,
    changeFieldType,
    handleBuildingValueChange,
    handleListingValueChange,
    handleAddSpace,
    setFieldsOrder,
    refreshAllListings,
    refreshBuildingValues,
    refreshListingValues,
    getFilteredBuildings,
    getSortedBuildings,
    getFilteredListings,
  }
}

export const useAllListings = ({ surveyId, kind = 'survey', enabled }) => {
  const user = useSelector((state) => state.user)
  const redirectNoPermissionUser = useRedirectNoPermission(user)

  const apiMethod =
    kind === 'database' ? Api.getDatabaseListings : Api.getSurveyListings
  const key =
    kind === 'database'
      ? `databases/${surveyId}/all_listings`
      : `surveys/${surveyId}/all_listings`

  const { data, error, mutate } = useSWR(
    surveyId && enabled ? key : null,
    async () => {
      return apiMethod({ id: surveyId }).then((results) => {
        if (isBadAuthResponseMessage(results.detail)) {
          return redirectNoPermissionUser()
        }
        if (!results) {
          return []
        }

        return {
          surveyBuildings: results.survey_buildings,
          surveyListings: results.survey_listings,
        }
      })
    }
  )

  return {
    isLoading: !data,
    surveyBuildings: data?.surveyBuildings || [],
    surveyListings: data?.surveyListings || [],
    error,
    mutate,
  }
}

/**
 * Get sorting function by custom field data_type
 * @param {Number} dataType
 * @returns {(a: any, b: any) => number} sorting function
 */
export const getSortFunctionByType = (dataType) => {
  switch (dataType) {
    case BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.NUMBER.id:
    case BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.SIZE_SQFT.id:
    case BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.CURRENCY_USD.id:
      return (a, b) => {
        if (!a) return 1
        if (!b) return -1

        return a - b
      }
    case BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.DATE.id:
      return (a, b) => {
        if (!a) return 1
        if (!b) return -1

        return compareAsc(parseISO(a), parseISO(b))
      }
    case BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.LIST.id:
      return (a, b) => {
        if (!a) return 1
        if (!b) return -1

        return a.join(',').localeCompare(b.join(','))
      }
    case BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.id:
    case BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.MULTILINE_STRING.id:
    default:
      return (a, b) => {
        if (!a) return 1
        if (!b) return -1

        return a.localeCompare(b)
      }
  }
}

export const getFilterFunctionByType = (type) => {
  switch (type) {
    case BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.id:
    case BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.MULTILINE_STRING.id:
      return (filter, value) => {
        return filter.includes(value)
      }
    case BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.NUMBER.id:
    case BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.SIZE_SQFT.id:
    case BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.CURRENCY_USD.id:
      return (filter, value) => {
        const { value: min, maxValue: max } = parseValue(filter)

        return (!min || value >= min) && (!max || value <= max)
      }
    case BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.DATE.id:
      return (filter, value) => {
        const { start, end } = filter

        if (!start && !end) {
          return true
        }

        const startDate = start ? parseISO(start) : null
        const endDate = end ? parseISO(end) : null
        const valueDate = value ? parseISO(value) : null

        if (startDate && !endDate) {
          return compareAsc(valueDate, startDate) >= 0
        }

        if (endDate && !startDate) {
          return compareAsc(endDate, valueDate) >= 0
        }

        if (startDate && endDate) {
          return (
            compareAsc(valueDate, startDate) >= 0 &&
            compareAsc(endDate, valueDate) >= 0
          )
        }

        return true
      }
    case BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.LIST.id:
      return (filter, value) => {
        return (value || []).every((v) => filter.includes(v))
      }
    default:
      return () => true
  }
}

const defaultOperator = { operator: 'range', value: '', maxValue: '' }
// Parse the existing value string into operator and values
const parseValue = (str) => {
  if (!str) return defaultOperator
  if (str.includes('-')) {
    const [min, max] = str.split('-')
    return { operator: 'range', value: Number(min), maxValue: Number(max) }
  }
  const matches = str.match(/^([<>]=?|=)?(.+)$/)
  if (matches) {
    return { operator: matches[1] || '=', value: matches[2], maxValue: '' }
  }
  return defaultOperator
}
