// @ts-check
import React, { useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ButtonBase,
  TablePagination,
} from '@material-ui/core'
import groupBy from 'lodash/groupBy'
import clsx from 'clsx'
import { Typography } from '~/legacy/components'
import {
  PlusIcon,
  BuildingIcon,
  AISparkIcon,
  DocumentIcon,
} from '~/legacy/components/svgs'
import SquareCheckbox from '~/legacy/components/buttons/SquareCheckbox'
import {
  cellComponentMap,
  TableHeaderCell,
  StringCell,
} from './table-components'
import { useSearch } from '~/legacy/components/search-bar/use-search'

const ROWS_PER_PAGE_OPTIONS = [10, 20, 50]

export function BuildingsTab({
  buildingColumns,
  surveyBuildings,
  buildingValues,
  onValueChange,
  showFilters,
  selectedRows,
  onRowSelection,
  setModalName,
  onImportClick,
}) {
  const { id } = useParams()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0])

  const hasBuildings = (surveyBuildings?.length || 0) > 0
  const hasFilters = useSearch().filterCount > 0

  const valuesByBuilding = groupBy(buildingValues, 'building_id')

  const data = (surveyBuildings || []).map(({ building }) => {
    const values = []

    buildingColumns.forEach((column) => {
      const addressValue =
        column.reserved && column.modelName === 'address'
          ? {
              address: building.address,
              city: building.city,
              state: building.state,
              zip: building.zipcode,
              latitude: building.latitude,
              longitude: building.longitude,
            }
          : null

      const modelValue = column.reserved
        ? addressValue || building[column.modelName]
        : null
      const customFieldValue = !column.reserved
        ? valuesByBuilding[building.id]?.find(
            (item) => item.custom_field.id === column.field.id
          )
        : null

      values.push({
        id: column.reserved ? building.id : customFieldValue?.id,
        surveyId: id,
        value: column.reserved ? modelValue : customFieldValue?.value,
      })
    })

    return {
      ...building,
      values,
    }
  })

  const paginatedData = useMemo(() => {
    const buildings = data || []
    const startIndex = page * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    return buildings.slice(startIndex, endIndex)
  }, [data, page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const NoResults = ({ showFilters }) => {
    return (
      <>
        <div className={clsx('px-8 grow', showFilters && 'ml-[348px]')}>
          <div className="flex flex-row h-full bg-[#F3F3F3] rounded text-[#666] justify-center">
            <div className="w-1/3">
              <div className="relative top-[37%] text-center flex flex-col items-center">
                <BuildingIcon />
                <Typography variant="emptyStateHeader" className="mt-2 mb-1.5">
                  Add building with address
                </Typography>

                <Typography className="w-[264px] text-center mb-[8.5px]">
                  Add a building to your database by entering the address.
                </Typography>

                <ButtonBase
                  disableRipple
                  onClick={() => setModalName('addBuilding')}
                >
                  <Typography
                    variant="boldText"
                    color="primary"
                    className="font-semibold leading-[22px]"
                  >
                    Add Building
                  </Typography>
                </ButtonBase>
              </div>
            </div>

            <div className="w-1/3">
              <div className="relative top-[37%] text-center flex flex-col items-center border-0 border-l border-solid border-[#D9D9D9]">
                <DocumentIcon />
                <Typography variant="emptyStateHeader" className="mt-2 mb-1.5">
                  Import data from CSV
                </Typography>

                <Typography className="w-[264px] text-center mb-[8.5px]">
                  Easily add multiple buildings and spaces at once using a CSV.
                </Typography>

                <ButtonBase disableRipple onClick={() => onImportClick('csv')}>
                  <Typography
                    variant="boldText"
                    color="primary"
                    className="font-semibold leading-[22px]"
                  >
                    Upload a CSV
                  </Typography>
                </ButtonBase>
              </div>
            </div>

            <div className="w-1/3">
              <div className="relative top-[37%] text-center flex flex-col items-center border-0 border-l border-solid border-[#D9D9D9]">
                <AISparkIcon />
                <Typography variant="emptyStateHeader" className="mt-2 mb-1.5">
                  Import data from PDF
                </Typography>

                <Typography className="w-[264px] text-center mb-[8.5px]">
                  Use AI to import building and space data from a PDF or image.
                </Typography>

                <ButtonBase disableRipple onClick={() => onImportClick('pdf')}>
                  <Typography
                    variant="boldText"
                    color="primary"
                    className="font-semibold leading-[22px]"
                  >
                    Upload a PDF or image
                  </Typography>
                </ButtonBase>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {hasBuildings || hasFilters ? (
        <div className={clsx('w-full ml-8', showFilters && 'ml-[348px]')}>
          <div className="absolute top-[122px] left-0 bottom-4 w-8 bg-white z-10" />
          <TableContainer className="flex-none overflow-visible w-full h-full">
            <Table size="small" className="border-separate pr-8">
              <TableHead className="sticky top-[122px] z-[3]">
                <TableRow>
                  {buildingColumns.map((header, arrayIndex) => {
                    return (
                      <TableHeaderCell
                        key={header.field?.id || header.displayName}
                        allColumns={buildingColumns}
                        header={header}
                        index={arrayIndex}
                        filtersVisible={showFilters}
                      />
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((building, rowIndex) => {
                  const rowCount = data.length

                  return (
                    <TableRow key={building.id} data-row-id={building.id}>
                      {building.values.map((cell, cellIndex) => {
                        const header = buildingColumns[cellIndex]
                        const DisplayCell =
                          cellComponentMap[header.fieldDataType.id] ??
                          StringCell

                        const isDescription =
                          header.displayName === 'Building Notes'

                        const isAddress = header.displayName === 'Address'
                        const checked = selectedRows.includes(building.id)

                        return (
                          <TableCell
                            key={`${header.displayName}-${building.id}`}
                            data-value-id={`${header.displayName}-${building.id}`}
                            className={clsx(
                              'w-[100px] py-3 px-4 h-20 bg-white',
                              'border-0 border-solid border-[#E0E0E0] border-l',
                              'first:sticky first:z-[1] first:border-r-2 last:border-r',
                              rowIndex !== 0 && 'border-t',
                              rowIndex === rowCount - 1 && 'border-b',
                              isDescription && 'max-w-[344px]',
                              isAddress && 'min-w-[368px] text-left',
                              cellIndex === 1 && 'border-l-0',
                              showFilters
                                ? 'first:left-[348px]'
                                : 'first:left-8'
                            )}
                          >
                            <div className="flex max-h-14 items-center gap-2">
                              {isAddress ? (
                                <SquareCheckbox
                                  checked={checked}
                                  edge={false}
                                  className="text-[#111]"
                                  onClick={() => {
                                    onRowSelection(building.id)
                                  }}
                                />
                              ) : null}
                              <DisplayCell
                                cell={cell || {}}
                                header={header}
                                onChange={(_, value) => {
                                  onValueChange({
                                    entityId: building.id,
                                    header,
                                    cell,
                                    value,
                                  })
                                }}
                              />
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}

                {hasBuildings ? (
                  <TableRow data-id="add-building-row">
                    <TableCell
                      className={clsx(
                        'w-[100px] py-3 px-4 h-20',
                        'border-0 border-dotted border-[#E0E0E0] first:border-l first:rounded-bl border-b last:border-r last:rounded-br',
                        'sticky z-[1] bg-white',
                        showFilters ? 'first:left-[348px]' : 'first:left-8'
                      )}
                    >
                      <ButtonBase
                        disableRipple
                        onClick={() => setModalName('addBuilding')}
                      >
                        <Typography
                          variant="boldText"
                          color="primary"
                          className="flex items-center gap-[18px]"
                        >
                          <PlusIcon />
                          Add Building
                        </Typography>
                      </ButtonBase>
                    </TableCell>
                    <TableCell
                      className="border-0 border-dotted border-[#E0E0E0] first:border-l first:rounded-bl border-b last:border-r last:rounded-br"
                      colSpan={buildingColumns.length - 1}
                    />
                  </TableRow>
                ) : (
                  <TableRow data-id="add-building-row">
                    <TableCell
                      className={clsx(
                        'py-3 px-4 h-20',
                        'border-0 border-solid border-[#E0E0E0] first:border-l first:rounded-bl border-b last:border-r last:rounded-br',
                        'sticky z-[1] bg-white',
                        showFilters ? 'first:left-[348px]' : 'first:left-8'
                      )}
                    >
                      <Typography
                        variant="boldText"
                        color="primary"
                        className="flex items-center gap-[18px]"
                      >
                        No results for the current filters
                      </Typography>
                    </TableCell>
                    <TableCell
                      className="border-0 border-solid border-[#E0E0E0] first:border-l first:rounded-bl border-b last:border-r last:rounded-br"
                      colSpan={buildingColumns.length - 1}
                    />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="sticky bottom-0 bg-white z-[2] w-[95vw] max-w-[800px]">
            <TablePagination
              component="div"
              count={surveyBuildings?.length || 0}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              className="sticky bottom-0 bg-white z-[2] w-[95vw] max-w-[800px]"
            />
          </div>
        </div>
      ) : (
        <NoResults showFilters={showFilters} />
      )}
    </>
  )
}
