import AnalyticsRaw from 'analytics';
import segmentPlugin from '@analytics/segment';
import { isTenantUserType, isBrokerUserType } from '~/legacy/utils';

/**
 * NOTE: For all events, we use the event send time versus our DB created_at time.
 * Not all events map to a DB row. We could change this in the future for relevant
 * events if we want.
 */

// How the user type will be displayed in MP
export const getSegmentUserTypeDisplay = (userType) => {
  if (isTenantUserType(userType)) {
    return 'Tenant';
  }
  if (isBrokerUserType(userType)) {
    return 'Broker';
  }
  return 'Unknown';
};

export const formatId = (id) => {
  return parseInt(id, 10);
};

export const Analytics = AnalyticsRaw({
  app: 'leaseup-web',
  plugins: [segmentPlugin({ writeKey: process.env.APP_SEGMENT_API_KEY,    page: {
      trackOnChange: false
    } })],
});

// Encourage consistency for param names across events so we can group more easily
const USER_ID = 'id';
const USER_TYPE = 'User Type';
const SURVEY_LISTING_COMMENT_TYPE = 'SURVEY_LISTING_COMMENT';
const SURVEY_BUILDING_COMMENT_TYPE = 'SURVEY_BUILDING_COMMENT';
const SURVEY_COMMENT_TYPE = 'SURVEY_COMMENT';
const SURVEY_COMPARISON_COMMENT_TYPE = 'SURVEY_COMPARISON_COMMENT';
export const SEGMENT_PARAM_NAMES = {
  USER_ID,
  SURVEY_ID: 'Survey ID',
  SURVEY_NAME: 'Survey Name',
  LISTING_ID: 'Listing ID',
  LISTING_IDS: 'Listing IDs',
  BUILDING_ID: 'Building ID',
  BUILDING_IDS: 'Building IDs',
  SURVEY_BUILDING_ID: 'Survey Building ID',
  SURVEY_BUILDING_INTEREST: 'Interest',
  SURVEY_LISTING_ID: 'Survey Listing ID',
  COMPANY_ID: 'Company ID',
  COMPANY_NAME: 'Company Name',
  COMPANY_SUB_DOMAIN: 'Company Sub Domain',
  USER_TYPE,
  SHARED_USER_ID: `Shared User ID`,
  SHARED_USER_EMAIL: 'Shared User Email',
  SHARED_USER_TYPE: `Shared ${USER_TYPE}`,
  COMMENT_TYPE: `Comment Type`,
  NUMBER_ADDITIONAL_FIELDS: 'Number Additional Fields',
  EXPORTED_USER_ID: `Exported User ID`,
  PARTIAL: 'Partial',
  LISTING_CUSTOM_FIELD_ID: 'Listing Custom Field ID',
  LISTING_CUSTOM_FIELD_IDS: 'Listing Custom Field IDs',
  BUILDING_CUSTOM_FIELD_ID: 'Building Custom Field ID',
  FIELD_NAME: 'Field Name',
  FIELD_NAME_NEW: 'Field Name New',
  FIELD_DATA_TYPE: 'Data Type',
  COPY_SPACES_FROM_SURVEY_ID: 'Copy Spaces From Survey ID',
};

export const Segment = {
  // Track the change in a survey listing status
  trackSurveyListingStatus: (statusChange) => {
    Analytics.track('Survey Listing Status Change', {
      'Status Change': statusChange,
    });
  },

  // Track the creation of a survey listing comment
  trackCreateSurveyListingComment: ({
    surveyId,
    listingId,
    hasText = false,
    numImages = 0,
    numAttachments = 0,
  }) => {
    Analytics.track('User Comment', {
      [SEGMENT_PARAM_NAMES.SURVEY_ID]: formatId(surveyId),
      [SEGMENT_PARAM_NAMES.LISTING_ID]: formatId(listingId),
      'Has Text': hasText,
      'Number of Images': numImages,
      'Number of Attachments': numAttachments,
      [SEGMENT_PARAM_NAMES.COMMENT_TYPE]: SURVEY_LISTING_COMMENT_TYPE,
    });
  },

  // Track the creation of a survey building comment
  trackCreateSurveyBuildingComment: ({
    surveyId,
    buildingId,
    hasText = false,
    numImages = 0,
  }) => {
    Analytics.track('User Comment', {
      [SEGMENT_PARAM_NAMES.SURVEY_ID]: formatId(surveyId),
      [SEGMENT_PARAM_NAMES.BUILDING_ID]: formatId(buildingId),
      'Has Text': hasText,
      'Number of Images': numImages,
      [SEGMENT_PARAM_NAMES.COMMENT_TYPE]: SURVEY_BUILDING_COMMENT_TYPE,
    });
  },

  // Track the creation of a survey listing comment
  trackCreateSurveyComment: ({ surveyId }) => {
    Analytics.track('User Comment', {
      [SEGMENT_PARAM_NAMES.SURVEY_ID]: formatId(surveyId),
      [SEGMENT_PARAM_NAMES.COMMENT_TYPE]: SURVEY_COMMENT_TYPE,
    });
  },

  // Track the creation of a survey listing comment
  trackCreateSurveyComparisonComment: ({ surveyId }) => {
    Analytics.track('User Comment', {
      [SEGMENT_PARAM_NAMES.SURVEY_ID]: formatId(surveyId),
      [SEGMENT_PARAM_NAMES.COMMENT_TYPE]: SURVEY_COMPARISON_COMMENT_TYPE,
    });
  },

  // Track creating a survey
  trackCreateSurvey: (surveyId, surveyName) => {
    Analytics.track('Create Survey', {
      [SEGMENT_PARAM_NAMES.SURVEY_ID]: formatId(surveyId),
      [SEGMENT_PARAM_NAMES.SURVEY_NAME]: surveyName,
    });
  },

  // Track adding a listing to a survey
  trackAddListingToSurvey: (surveyId, listingId) => {
    Analytics.track('Add Listing to Survey', {
      [SEGMENT_PARAM_NAMES.SURVEY_ID]: formatId(surveyId),
      [SEGMENT_PARAM_NAMES.LISTING_ID]: formatId(listingId),
    });
  },

  // Track creating a listing
  trackCreateListing: ({
    listingId,
    buildingId,
    numAdditionalFields = null,
    surveyId = null,
  }) => {
    const params = {
      [SEGMENT_PARAM_NAMES.LISTING_ID]: formatId(listingId),
      [SEGMENT_PARAM_NAMES.BUILDING_ID]: formatId(buildingId),
      [SEGMENT_PARAM_NAMES.NUMBER_ADDITIONAL_FIELDS]: numAdditionalFields,
    };
    if (surveyId) {
      params[SEGMENT_PARAM_NAMES.SURVEY_ID] = formatId(surveyId);
    }
    Analytics.track('Create Listing', params);
  },

  trackDeleteListingSimple: ({ listingId, buildingId }) => {
    const params = {
      [SEGMENT_PARAM_NAMES.LISTING_ID]: formatId(listingId),
      [SEGMENT_PARAM_NAMES.BUILDING_ID]: formatId(buildingId),
    };
    Analytics.track('Delete Listing', params);
  },

  // Track sharing a survey with a user
  trackShareSurvey: (
    surveyId,
    sharedUserId,
    sharedUserEmail,
    sharedUserType,
    hasMessage = false
  ) => {
    Analytics.track('Share Survey', {
      [SEGMENT_PARAM_NAMES.SURVEY_ID]: formatId(surveyId),
      [SEGMENT_PARAM_NAMES.SHARED_USER_ID]: formatId(sharedUserId),
      [SEGMENT_PARAM_NAMES.SHARED_USER_EMAIL]: sharedUserEmail,
      [SEGMENT_PARAM_NAMES.SHARED_USER_TYPE]:
        getSegmentUserTypeDisplay(sharedUserType),
      'Has Message': hasMessage,
    });
  },

  trackPdfExport: (surveyId, exportedByUserId) => {
    Analytics.track('Export Pdf', {
      [SEGMENT_PARAM_NAMES.SURVEY_ID]: formatId(surveyId),
      [SEGMENT_PARAM_NAMES.EXPORTED_USER_ID]: formatId(exportedByUserId),
    });
  },
};
