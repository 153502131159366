import { useEffect, useState } from 'react'
import { useMe } from '~/support/useMe'
import {
  Analytics,
  getSegmentUserTypeDisplay,
  Segment,
  SEGMENT_PARAM_NAMES,
} from './segment'

// LEGACY, please use Segment directly
export const useSegment = () => Segment

export const useTrackPageView = ({ eventName, url, path, computedMatch }) => {
  // Track a page view for a logged in user
  const [hasTracked, setHasTracked] = useState(false)
  useEffect(() => {
    if (path && !hasTracked) {
      const eventNamePresent = eventName || path
      // Gather the URL params
      let params = {}
      if (computedMatch && computedMatch.params) {
        params = Object.fromEntries(
          Object.entries(computedMatch.params).map(([k, v]) => [
            [`param_${k}`],
            v,
          ])
        )
      }
      Analytics.page({
        title: eventNamePresent,
        path,
        url,
        ...params,
      })
      setHasTracked(true)
    }
  }, [eventName, url, path, computedMatch, hasTracked])
}

export const useIdentifyUserV1 = ({ user }) => {
  // Identify the user, updating their details
  const [hasTracked, setHasTracked] = useState(false)
  useEffect(() => {
    if (user && user.id && user.loggedIn && !hasTracked) {
      Analytics.identify(`${user.id}`, {
        // Reserved props
        email: user.email,
        firstname: user.firstName,
        lastname: user.lastName,
        [SEGMENT_PARAM_NAMES.USER_ID]: user.id,
        // Set two company in 2 different ways. One works well with mixpanel, one with variance
        [SEGMENT_PARAM_NAMES.COMPANY_ID]: user.company ? user.company.id : null,
        [SEGMENT_PARAM_NAMES.COMPANY_NAME]: user.company
          ? user.company.name
          : null,
        [SEGMENT_PARAM_NAMES.COMPANY_SUB_DOMAIN]: user.company
          ? user.company.sub_domain
          : null,
        company: {
          id: user.company ? user.company.id : null,
          name: user.company ? user.company.name : null,
          sub_domain: user.company ? user.company.sub_domain : null,
        },
        [SEGMENT_PARAM_NAMES.USER_TYPE]: getSegmentUserTypeDisplay(
          user.userType
        ),
      })
      setHasTracked(true)
    }
  }, [user, hasTracked])
}

export const useIdentifyUser = () => {
  const { data: me } = useMe()
  
  useEffect(() => {
    if (me === null) {
      Analytics.identify(null)
    } else if (me) {
      Analytics.identify(
        String(me.id),
        {
          email: me.email,
          username: me.email,
          name: me.name,
          firstName: me.firstName,
          lastName: me.lastName,
          photo: me.photo,
          company: {
            id: me.companyId,
            name: me.company,
          },
          subdomain: me.subdomain,
          role: me.role,
          isAdmin: me.isAdmin,
          flags: me.flags,
          createdAt: me.createdAt,
        },
        {
          Intercom: {
            user_hash: me.intercomToken,
            hide_default_launcher: me.role === 'Client',
          },
        }
      )
    }
  }, [me]) // Only re-run when user data changes
}
