import bulkEdit from './bulk-edit'
import shared from './shared'
import survey from './survey'
import settings from './settings'

export default {
  shared: { ...shared },
  bulkEdit: { ...bulkEdit },
  survey: { ...survey },
  settings: { ...settings },
}
