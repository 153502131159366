// @ts-check
import useSWR from 'swr'
import _ from 'lodash'
import { useMemo } from 'react'
import { surveyApi, customFieldApi } from '~/legacy/fetchApi'
import { defaultMutateBinder, defaultMutateOptimisticBinder } from './selectors'

const DEFAULT_API_ERROR_MESSAGE =
  'Error updating custom fields, please try again.'

/**
 * SWR hook to get all custom fields of a survey or database
 *
 * @param {Object} options
 * @param {Number} [options.surveyId]
 * @param {'survey' | 'database'} [options.kind]
 * @param {Boolean} [options.enabled=true]
 */
export const useSurveyCustomFieldsSelector = ({
  surveyId,
  kind = 'survey',
  enabled = true,
}) => {
  const isDatabase = kind === 'database'

  // Only the endpoint changes based on the type
  const key = isDatabase
    ? `databases/${surveyId}/all_custom_fields`
    : `surveys/${surveyId}/all_custom_fields`

  const {
    data: survey,
    error,
    mutate,
  } = useSWR(enabled && surveyId ? key : null, async () => {
    return surveyApi
      .getCustomFields({ surveyId, kind })
      .then(([, response]) => ({
        ...response,
        timestamp: new Date().getTime(),
      }))
  })

  const {
    sortedCustomFields: customFields,
    buildingCustomFields,
    listingCustomFields,
    buildingTemplateId,
    listingTemplateId,
    fieldMap,
  } = useMemo(() => {
    const customFields = survey?.custom_fields || []
    const buildingTemplateId =
      survey?.templates && survey?.templates.length > 0
        ? survey.templates.find((t) => t.type === 'SURVEY_BUILDINGS')?.id
        : undefined

    const listingTemplateId =
      survey?.templates && survey?.templates.length > 0
        ? survey.templates.find((t) => t.type === 'SURVEY_SPACES')?.id
        : undefined

    const sortedCustomFields = _.orderBy(
      customFields,
      [(cf) => cf.template.id, (cf) => cf.order],
      ['asc', 'asc']
    )

    const buildingCustomFields = sortedCustomFields.filter(
      (cf) => cf.template.id === buildingTemplateId
    )

    const listingCustomFields = sortedCustomFields.filter(
      (cf) => cf.template.id === listingTemplateId
    )

    const fieldMap = new Map(customFields.map((field) => [field.id, field]))

    return {
      sortedCustomFields,
      buildingCustomFields,
      listingCustomFields,
      buildingTemplateId,
      listingTemplateId,
      fieldMap,
    }
  }, [survey])

  // Bind our mutators with our default settings and error handling
  const mutateSurveyCustomFields = defaultMutateBinder(
    mutate,
    DEFAULT_API_ERROR_MESSAGE
  )

  const mutateSurveyCustomFieldsOptimistic = defaultMutateOptimisticBinder(
    mutateSurveyCustomFields,
    DEFAULT_API_ERROR_MESSAGE
  )

  const incorporateCustomFields = (newData) => {
    return {
      ...survey,
      custom_fields: newData,
    }
  }

  const mutateChangeCustomFieldType = async ({
    id,
    newType,
    newValue,
    optimistic = true,
  }) => {
    const apiMutator = async () =>
      customFieldApi
        .changeFieldType({
          fieldId: id,
          type: newType,
          value: newValue,
        })
        .then(([, response]) => {
          const index = customFields.findIndex(
            (item) => item.id === response.id
          )

          return incorporateCustomFields([
            ...customFields.slice(0, index),
            response,
            ...customFields.slice(index + 1),
          ])
        })

    if (optimistic) {
      const index = customFields.findIndex((item) => item.id === id)
      const element = customFields[index]

      const newData = incorporateCustomFields([
        ...customFields.slice(0, index),
        { ...element, data_type: newType, value: newValue },
        ...customFields.slice(index + 1),
      ])

      return mutateSurveyCustomFieldsOptimistic({
        newObject: newData,
        mutator: apiMutator,
      })
    }

    return mutateSurveyCustomFields({ mutator: apiMutator })
  }

  const mutateChangeCustomFieldLabel = async ({
    id,
    newName,
    optimistic = true,
  }) => {
    const apiMutator = async () =>
      customFieldApi
        .updateFieldLabel({
          fieldId: id,
          label: newName,
        })
        .then(([, response]) => {
          const index = customFields.findIndex(
            (item) => item.id === response.id
          )

          return incorporateCustomFields([
            ...customFields.slice(0, index),
            response,
            ...customFields.slice(index + 1),
          ])
        })

    if (optimistic) {
      const index = customFields.findIndex((item) => item.id === id)
      const element = customFields[index]

      const newData = incorporateCustomFields([
        ...customFields.slice(0, index),
        { ...element, label: newName },
        ...customFields.slice(index + 1),
      ])

      return mutateSurveyCustomFieldsOptimistic({
        newObject: newData,
        mutator: apiMutator,
      })
    }

    return mutateSurveyCustomFields({ mutator: apiMutator })
  }

  const mutateDeleteCustomField = async ({ id, optimistic = true }) => {
    const apiMutator = async () =>
      customFieldApi
        .deleteField({
          fieldId: id,
        })
        .then(() => {
          return incorporateCustomFields(
            customFields.filter((item) => item.id !== id)
          )
        })

    if (optimistic) {
      return mutateSurveyCustomFieldsOptimistic({
        newObject: incorporateCustomFields(
          customFields.filter((item) => item.id !== id)
        ),
        mutator: apiMutator,
      })
    }

    return mutateSurveyCustomFields({ mutator: apiMutator })
  }

  const mutateCreateCustomField = async ({
    label,
    dataType = 1,
    order,
    optimistic = true,
    isBuildingField = true,
  }) => {
    const templateId = isBuildingField ? buildingTemplateId : listingTemplateId
    const apiMutator = async () =>
      customFieldApi
        .createField({
          surveyId,
          templateType: isBuildingField ? 'buildings' : 'listings',
          label,
          dataType,
          order,
        })
        .then(([, response]) => {
          return incorporateCustomFields([...customFields, response])
        })

    if (optimistic && templateId) {
      return mutateSurveyCustomFieldsOptimistic({
        newObject: incorporateCustomFields([
          ...customFields,
          {
            label,
            data_type: dataType,
            order,
            template: { id: templateId },
          },
        ]),
        mutator: apiMutator,
      })
    }

    return mutateSurveyCustomFields({ mutator: apiMutator })
  }

  const mutateSetCustomFieldOrder = async ({
    fieldOrder,
    isBuildingFields = true,
  }) => {
    const templateId = isBuildingFields ? buildingTemplateId : listingTemplateId
    const apiMutator = async () =>
      surveyApi
        .setFieldOrder({
          surveyId,
          kind,
          fieldOrder,
          templateId,
        })
        .then(([, response]) => {
          return incorporateCustomFields(response.custom_fields)
        })

    return mutateSurveyCustomFields({ mutator: apiMutator })
  }

  const mutateChangeCustomFieldTemplate = async ({ id, newType }) => {
    const apiMutator = async () =>
      customFieldApi
        .changeFieldTemplate({
          surveyId,
          fieldId: id,
          templateType: newType,
        })
        .then(([, response]) => {
          const index = customFields.findIndex(
            (item) => item.id === response.id
          )
          return incorporateCustomFields([
            ...customFields.slice(0, index),
            response,
            ...customFields.slice(index + 1),
          ])
        })

    return mutateSurveyCustomFields({ mutator: apiMutator })
  }

  return {
    key,
    survey,
    customFields,
    buildingCustomFields,
    listingCustomFields,
    fieldMap,
    error,
    isLoading: !survey,
    mutate,
    mutateChangeCustomFieldType,
    mutateChangeCustomFieldLabel,
    mutateDeleteCustomField,
    mutateCreateCustomField,
    mutateSetCustomFieldOrder,
    mutateChangeCustomFieldTemplate,
  }
}
