// @ts-check
import React, { useMemo } from 'react'
import clsx from 'clsx'
import Slide from '@material-ui/core/Slide'
import { useParams } from 'react-router-dom'
import { ClearFiltersButton, FilterLabel } from './search-bar/search-controls'
import {
  CustomFieldInput,
  StandardInput,
} from '~/legacy/components/search-bar/custom-field-controls'
import { useBulkEdit } from '~/legacy/pages/Surveys/Survey/bulk-edit-collection/use-bulk-edit'
import { useSearch } from '~/legacy/components/search-bar/use-search'

export default function SearchBar({ columnStats }) {
  const { id: surveyId } = useParams()
  const {
    buildingName,
    fullAddressMatches,
    buildingCustom,
    spaceCustom,
    debouncedSetQuery,
    tab: selectedTab,
  } = useSearch()

  const {
    buildingCustomFields,
    listingCustomFields,
    isLoading: isLoadingFields,
  } = useBulkEdit({ surveyId, kind: 'database', enabled: Boolean(surveyId) })

  const visibleFields = useMemo(() => {
    if (selectedTab === 'buildings') {
      return buildingCustomFields
    }
    return listingCustomFields
  }, [selectedTab, buildingCustomFields, listingCustomFields])

  const handleCustomFieldChange = (fieldId, value) => {
    const newBuildingFilter =
      selectedTab === 'buildings' ? { [fieldId]: value } : {}
    const newSpaceFilter = selectedTab === 'spaces' ? { [fieldId]: value } : {}

    debouncedSetQuery({
      buildingName,
      fullAddressMatches,
      buildingCustom: {
        ...buildingCustom,
        ...newBuildingFilter,
      },
      spaceCustom: {
        ...spaceCustom,
        ...newSpaceFilter,
      },
    })
  }

  const handleFilterChange = (fieldId, value) => {
    debouncedSetQuery({
      buildingName,
      fullAddressMatches,
      buildingCustom: {
        ...buildingCustom,
      },
      spaceCustom: {
        ...spaceCustom,
      },
      [fieldId]: value,
    })
  }

  if (isLoadingFields) {
    return <div>Loading filters...</div>
  }

  return (
    <div className="space-y-6">
      {/* FIXME: Remove this */}
      <div className="flex gap-2">
        <ClearFiltersButton />
      </div>
      <div className="space-y-4">
        <div className="space-y-3">
          <FilterLabel label="Address">
            <StandardInput
              value={fullAddressMatches}
              onChange={(value) => {
                handleFilterChange('fullAddressMatches', value)
              }}
              placeholder="Full address, zip, city or state"
            />
          </FilterLabel>

          <FilterLabel label="Building Name">
            <StandardInput
              value={buildingName}
              onChange={(value) => {
                handleFilterChange('buildingName', value)
              }}
              placeholder="Search building name..."
            />
          </FilterLabel>

          {/* Custom Field Filters */}
          {visibleFields.length > 0 && (
            <div className="space-y-4">
              <div className="space-y-3">
                {visibleFields.map((field) => {
                  const distinctValues =
                    columnStats?.[field.id]?.fullDistinctValues ?? new Set()
                  const distinctCount =
                    columnStats?.[field.id]?.fullDistinctCount ?? 0

                  const newColumnStats = {
                    ...columnStats?.[field.id],
                    distinctValues,
                    distinctCount,
                  }

                  if (!distinctCount) return null

                  return (
                    <FilterLabel
                      key={field.id}
                      field={field}
                      label={field.label}
                      onClear={() => handleCustomFieldChange(field.id, {})}
                    >
                      <div className="min-w-0">
                        <CustomFieldInput
                          field={field}
                          value={
                            selectedTab === 'buildings'
                              ? buildingCustom[field.id]
                              : spaceCustom[field.id]
                          }
                          onChange={(value) => {
                            handleCustomFieldChange(field.id, value)
                          }}
                          columnStats={newColumnStats}
                        />
                      </div>
                    </FilterLabel>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const SearchBarDrawer = ({ isVisible, columnStats }) => {
  return (
    <Slide direction="right" in={isVisible} mountOnEnter unmountOnExit>
      <div className="fixed top-[182px] left-0 bottom-4 bg-white z-10 pl-8 pr-4 pb-8">
        <div
          className={clsx(
            'flex-shrink-0 border border-solid border-[#E0E0E0] p-5 rounded h-full overflow-auto w-[300px]'
          )}
        >
          <SearchBar columnStats={columnStats} />
        </div>
      </div>
    </Slide>
  )
}
