// @ts-check
import { fetchApi } from './rootApi'

const API_ROOT = 'survey_listing_custom_fields'

// TODO: add tracking for each of these

export const surveyListingCustomFieldApi = {
  list: async ({ surveyId, buildingId }) => {
    const queryParams = new URLSearchParams({ surveyId })
    if (buildingId) {
      queryParams.append('buildingId', buildingId)
    }

    return fetchApi({
      endpoint: `${API_ROOT}/?${queryParams}`,
      fetchArgs: {
        method: 'GET',
      },
    })
  },

  createFieldValue: async ({
    surveyId,
    listingId,
    customFieldId,
    value = '',
  }) => {
    return fetchApi({
      endpoint: `${API_ROOT}/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({
          surveyId,
          listingId,
          customFieldId,
          value,
        }),
      },
    })
  },

  updateFieldValue: async ({ surveyListingCustomFieldId, value }) =>
    fetchApi({
      endpoint: `${API_ROOT}/${surveyListingCustomFieldId}/change_field_value/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({ new_value: value }),
      },
    }),
}
