import get from 'lodash/get'
import translations from './en/index'

// FIXME: This file is is adding temporary functions to mimic the one used by i18n libraries
// until we get actual translations working. For now has the function of collenting all the copy we have in the app
const t = (key) => {
  return get(translations, key)
}

export const useTranslation = () => {
  return { t }
}
