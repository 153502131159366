// @ts-check
import { fetchApi } from './rootApi'
import { redirectOn401 } from './helpers'
import { formatId, SEGMENT_PARAM_NAMES } from '../utils'
import { BUILDING_INTEREST_LOOKUP } from '../consts'

export const surveyApi = {
  getSurvey: async ({
    surveyId,
    page = null,
    skipAuth = false,
    kind = 'survey',
  }) => {
    const rootEndpoint = kind === 'database' ? 'databases' : 'surveys'

    const promise = fetchApi({
      endpoint: `${rootEndpoint}/${surveyId}/?${new URLSearchParams({
        page,
      })}`,
      skipAuth,
    })
    promise.catch((error) => skipAuth && redirectOn401(error.response))
    return promise
  },

  updateSurveyBuildingStatuses: async ({
    surveyId,
    buildingIds,
    interest,
    kind = 'survey',
  }) => {
    const rootEndpoint = kind === 'database' ? 'databases' : 'surveys'

    return fetchApi({
      endpoint: `${rootEndpoint}/${surveyId}/update_survey_building_statuses/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({ building_ids: buildingIds, interest }),
      },
      trackingArgs: {
        name: 'Update Survey Building Interests',
        metadata: {
          [SEGMENT_PARAM_NAMES.BUILDING_IDS]: buildingIds.map((id) =>
            formatId(id)
          ),
          [SEGMENT_PARAM_NAMES.SURVEY_ID]: formatId(surveyId),
          [SEGMENT_PARAM_NAMES.SURVEY_BUILDING_INTEREST]:
            BUILDING_INTEREST_LOOKUP[interest],
        },
      },
    })
  },

  updatePartial: async ({ surveyId, partial, kind = 'survey' }) => {
    const rootEndpoint = kind === 'database' ? 'databases' : 'surveys'

    return fetchApi({
      endpoint: `${rootEndpoint}/${surveyId}/`,
      fetchArgs: {
        method: 'PATCH',
        body: JSON.stringify(partial),
      },
    })
  },

  setFieldOrder: async ({
    surveyId,
    fieldOrder,
    templateId,
    kind = 'survey',
  }) => {
    const rootEndpoint = kind === 'database' ? 'databases' : 'surveys'

    return fetchApi({
      endpoint: `${rootEndpoint}/${surveyId}/set_field_order/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({
          field_order: fieldOrder,
          template_id: templateId,
        }),
      },
    })
  },

  /**
   * Get all custom fields for a survey
   *
   * @param {Object} options Single object with all the options
   * @param {number} options.surveyId ID of the survey
   * @param {'survey' | 'database'} options.kind type of survey
   * @returns {Promise<[any, Survey]>}
   */

  getCustomFields: async ({ surveyId, kind = 'survey' }) => {
    const rootEndpoint = kind === 'database' ? 'databases' : 'surveys'

    return fetchApi({
      endpoint: `${rootEndpoint}/${surveyId}/all_custom_fields/`,
    })
  },

  getAvailableToAdd: async () =>
    fetchApi({
      endpoint: `surveys/available_surveys/`,
    }),

  copy: async ({
    kind = 'survey',
    surveyId,
    destinationSurveyId,
    listingIds,
    surveyName = '',
  }) => {
    const rootEndpoint = kind === 'database' ? 'databases' : 'surveys'

    return fetchApi({
      endpoint: `${rootEndpoint}/${surveyId}/copy/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({
          copy_to_survey_id: destinationSurveyId,
          listing_ids: listingIds,
          create_survey_name: surveyName,
        }),
      },
    })
  },
}

/**
 * @typedef Template
 * @type {object}
 *
 * @property {number} id
 * @property {string} name
 * @property {string} type
 */

/**
 * @typedef Project
 * @type {object}
 *
 * @property {number} id
 * @property {string} name
 */

/**
 * @typedef Company
 * @type {object}
 *
 * @property {number} id
 * @property {string} name
 */

/**
 * @typedef CustomField
 * @type {object}
 *
 * @property {number} id
 * @property {string} label
 * @property {number} order
 * @property {number} data_type
 * @property {Template} template
 */

/**
 * @typedef Survey
 * @type {object}
 *
 * @property {number} id
 * @property {string} name
 * @property {'survey' | 'database'} kind
 * @property {CustomField[]} custom_fields
 * @property {Template[]} templates
 * @property {Project} project
 * @property {Company} company
 */
