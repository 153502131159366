// @ts-check
import { Menu } from '@material-ui/core'
import React from 'react'
import {
  AddressIcon,
  EditBuildingMenuItem,
  RemoveBuildingFromSurveyMenuItem,
} from '~/legacy/components'
import { useDispatcher } from '~/libraries/messaging.react'
import { QuickMenuItem } from '../buttons/QuickMenuItem'
import { useTranslation } from '~/locales/translations'

export const SdpSurveyBuildingMenu = ({
  building,
  surveyId,
  handleMenuClose = () => {},
  ...menuProps
}) => {
  const dispatcher = useDispatcher()
  const { t } = useTranslation()

  return (
    <Menu {...menuProps}>
      <EditBuildingMenuItem buildingId={building.id} surveyId={surveyId} />
      <QuickMenuItem
        icon={AddressIcon}
        label={t('survey.buildingOptions.quickLocationEdit')}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          handleMenuClose()
          dispatcher.send({
            type: 'EditBuildingLocation',
            data: { building },
          })
        }}
      />
      <RemoveBuildingFromSurveyMenuItem
        surveyId={surveyId}
        buildingId={building.id}
        handleMenuClose={handleMenuClose}
        label={t('survey.buildingOptions.removeFromSurvey')}
      />
    </Menu>
  )
}
