// @ts-check
import {
  ButtonBase,
  Dialog,
  Drawer,
  Fade,
  Menu,
  MenuItem,
  Slide,
  TableCell,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react'
import { format } from 'date-fns'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { useParams } from 'react-router-dom'
import {
  ConfirmModalComponent,
  FieldDataTypeMenuSection,
  InlineEditableValue,
  SquareIconButton,
  Typography,
} from '~/legacy/components'
import {
  SnackbarUtils,
  arrayMove,
  BACKEND_FOR_DND,
  getViewBuildingRoute,
} from '~/legacy/utils'
import {
  BULK_IMPORT_CONSTANTS,
  BULK_IMPORT_HELPERS,
  getIconByDataTypeId,
} from '~/legacy/utils/bulkImportUtils'

import {
  EditableDateCell,
  EditableMultilineStringCell,
  EditableNumberCell,
  EditableStringCell,
} from '~/legacy/components/tableComponents'
import {
  CloseIcon,
  DeleteIcon,
  MenuSelectedItemIcon,
  DragIcon,
  PlusIcon,
  LogoIcon,
} from '~/legacy/components/svgs'
import { DeleteSpaceFieldModal as DeleteFieldModal } from '~/legacy/components/modalComponents/DeleteSpaceFieldModal'
import { useTranslation } from '~/locales/translations'
import { SearchButton } from '~/legacy/components/search-bar/search-controls'
import { useBulkEdit } from '~/legacy/pages/Surveys/Survey/bulk-edit-collection/use-bulk-edit'

const defaultStyleOverrides = (cell, isSemicolon) => {
  const defaultOverrides = {
    container: {
      padding: '8px',
      cursor: isSemicolon ? '' : 'pointer',
    },
  }
  if (cell.error) {
    defaultOverrides.hoverBackgroundColor = 'rgba(221, 66, 26, .15)'
  }
  return defaultOverrides
}

export function TableHeaderCell({ allColumns, header, index, filtersVisible }) {
  const IconComponent = header.icon
  const isSpacesHeader =
    header.fieldType.id === BULK_IMPORT_CONSTANTS.FIELD_TYPES.SPACE.id

  // If we only have Space Name and Space Notes
  const onlyTwoSpaceColumns = allColumns.length === 2

  return (
    <TableCell
      align="center"
      className={clsx(
        'bg-[#F9F9F9] h-12 max-h-12 py-1.5 pr-6 pl-6',
        'border-0 border-solid border-[#E0E0E0] border-l border-t border-b',
        'first:rounded-tl first:sticky first:border-r-2 first:z-[1] last:rounded-tr last:border-r',
        isSpacesHeader && 'border-b first:rounded-bl last:rounded-br',
        onlyTwoSpaceColumns && 'w-1/2',
        index === 1 && 'border-l-0',
        filtersVisible ? 'first:left-[348px]' : 'first:left-8'
      )}
      data-field-id={header.field?.id || header.displayName}
    >
      <Tooltip
        title={isSpacesHeader ? '' : 'Click column to sort'}
        disableFocusListener
        disableTouchListener
      >
        <div className="text-[#666] flex items-center">
          <SearchButton
            column={
              isSpacesHeader
                ? null
                : header.modelName || String(header.field.id)
            }
          >
            <IconComponent />
            <Typography
              noWrap
              variant="body2"
              style={{ letterSpacing: '.4px', fontFamily: 'Inter-Medium' }}
            >
              {header.displayName.toUpperCase()}
            </Typography>
          </SearchButton>
        </div>
      </Tooltip>
    </TableCell>
  )
}

// The cell displayed on the table to the user for the Address
const AddressFieldCell = ({ cell, header, onChange }) => {
  const addressValue = {
    GOOGLE_ADDRESS: { ...cell.value },
    ...cell.value.GOOGLE_AUTOCOMPLETE_RESULTS,
  }

  return (
    <InlineEditableValue
      classesIn={{}}
      styleOverrides={defaultStyleOverrides(cell)}
      hoverOnContainer
      placeholder="Enter a value"
      onClick={() => {}}
      type="address"
      fieldDataTypeId={header.fieldDataType.id}
      updateValueApiCallback={onChange}
      value={addressValue || null}
      formatDisplayValue={(newValue) => {
        return newValue
          ? {
              address: newValue.GOOGLE_ADDRESS.address,
              state: newValue.GOOGLE_ADDRESS.state,
              city: newValue.GOOGLE_ADDRESS.city,
              zipcode: newValue.GOOGLE_ADDRESS.zipcode,
            }
          : null
      }}
      prepareNewValue={(newValue) => {
        return {
          ...newValue.GOOGLE_AUTOCOMPLETE_RESULTS,
          GOOGLE_ADDRESS: { ...newValue.GOOGLE_ADDRESS },
        }
      }}
      extraProps={{
        isBulkEdit: true,
        buildingUrl: getViewBuildingRoute(cell.surveyId, cell.id),
      }}
    />
  )
}

// The cell displayed on the table to the user for numeric types
export const NumericFieldCell = ({ cell, header, onChange }) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <EditableNumberCell
      value={rawValue}
      updateValueApiCallback={onChange}
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? rawValue || null : undefined}
      formatDisplayValue={() =>
        !cell.error
          ? BULK_IMPORT_HELPERS.getNumberValueForDisplay(
              rawValue,
              header.fieldDataType.id
            )
          : rawValue
      }
    />
  )
}

// Date cells
export const DateFieldCell = ({ cell, header, onChange }) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  const noTimeRawValue =
    cell.error || !rawValue
      ? rawValue
      : BULK_IMPORT_HELPERS.prepareDate(rawValue)

  return (
    <EditableDateCell
      value={noTimeRawValue}
      updateValueApiCallback={onChange}
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? noTimeRawValue : undefined}
      formatDisplayValue={(valueToFormat) =>
        valueToFormat && !cell.error
          ? format(valueToFormat, 'MMM d, y')
          : valueToFormat
      }
    />
  )
}

export const ListSemicolonCell = ({ cell, header, onChange }) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <InlineEditableValue
      classesIn={{}}
      styleOverrides={defaultStyleOverrides(cell, true)}
      hoverOnContainer
      placeholder="Enter a value"
      autoFocus={false}
      onClick={() => {}}
      type="multi-select"
      fieldDataTypeId={header.fieldDataType.id}
      options={[]}
      value={rawValue}
      displayValueOverride={cell.error ? rawValue : undefined}
      updateValueApiCallback={onChange}
    />
  )
}

export const StringCell = ({
  cell,
  header,
  onChange,
  CellClass = EditableStringCell,
}) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <CellClass
      value={rawValue}
      updateValueApiCallback={onChange}
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? rawValue : undefined}
      field={{ label: header.displayName }}
    />
  )
}

export const MultilineStringCell = (props) => (
  <StringCell CellClass={EditableMultilineStringCell} {...props} />
)
export const WhiteTextField = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottomColor: '#e0e0e0', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#666', // Solid underline on hover
    },
    '& .MuiInputBase-input': {
      height: '22px',
    },
  },
})(TextField)

export const ManageFieldsDrawer = ({ open, onClose, fields, selectedTab }) => {
  const { id: surveyId } = useParams()
  const fieldType = fields[0].fieldType.name
  const [orderedFields, setOrderedFields] = useState(fields)
  const newFieldRef = useRef(null)
  const [newField, setNewField] = useState('')
  const { t } = useTranslation()

  const AddNewIconClass = BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.icon

  const {
    survey,
    setFieldsOrder,
    deleteField,
    createCustomField,
    renameField,
    changeFieldDataType,
    changeFieldType,
    refreshBuildingValues,
    refreshListingValues,
  } = useBulkEdit({ surveyId, kind: 'database' })

  const nextOrder = useMemo(() => {
    const customFields = fields.filter((column) => !column.reserved)
    if (customFields.length > 0) {
      const lastOrder = customFields[customFields.length - 1].field.order
      return lastOrder ? lastOrder + 1 : undefined
    }
    return undefined
  }, [fields])

  /**
   *
   * @async
   * @param {Object} payload
   * @param {string} payload.label
   * @param {number} payload.order
   * @param {('building'|'space')} payload.type
   */
  const handleAddField = async ({ label, order, type }) => {
    let error = ''
    if (!label.trim()) {
      return
    }

    const uniqueFieldNames = new Set(
      fields.map((item) => item.displayName.toLowerCase())
    )

    if (uniqueFieldNames.has(label.toLowerCase())) {
      error = `You already have a field named ${label}`
    }

    if (error) {
      SnackbarUtils.error(error)
    } else {
      const data = await createCustomField({
        label,
        order,
        isBuildingField: type === 'building',
        optimistic: false,
      })
      console.log({ data })
      SnackbarUtils.success(t('bulkEdit.toasts.fieldAdded'))
      setNewField('')
    }
  }

  useEffect(() => {
    setOrderedFields(fields)
  }, [fields])

  const moveField = useCallback(
    (dragIndex, hoverIndex) => {
      setOrderedFields((a) => arrayMove(a, dragIndex, hoverIndex))
    },
    [setOrderedFields]
  )

  const didOrderChange = useMemo(() => {
    return (
      fields.map((item) => item.displayName).join(',') !==
      orderedFields.map((item) => item.displayName).join(',')
    )
  }, [fields, orderedFields])

  return (
    <Dialog
      id="dialog-backdrop"
      maxWidth={false}
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'left' }}
    >
      <Drawer
        anchor="right"
        classes={{
          paper: 'drawer-width',
        }}
        open={open}
        onClose={onClose}
        variant="persistent"
      >
        <div className="flex border-solid border-0 border-b border-b-[#E0E0E0] pt-[15px] pr-4 pb-4 pl-6 sticky top-0 bg-white z-[3]">
          <Typography variant="h2" className="flex text-lg leading-6 my-auto">
            Manage fields
          </Typography>
          <div className="ml-auto">
            <SquareIconButton onClick={() => onClose()}>
              <CloseIcon />
            </SquareIconButton>
          </div>
        </div>
        <Fade in={open}>
          <div className="p-6">
            <div className="flex items-center justify-between pb-5">
              <Typography variant="bodyBold">
                {`${fields.length} ${fieldType} Fields`}
              </Typography>

              <ButtonBase
                disableRipple
                onClick={() => {
                  newFieldRef.current.focus()
                }}
              >
                <Typography
                  variant="boldText"
                  color="primary"
                  className="flex items-center gap-1"
                >
                  <PlusIcon />
                  Add field
                </Typography>
              </ButtonBase>
            </div>

            <DndProvider backend={BACKEND_FOR_DND}>
              <div className="flex flex-col bg-[#F9F9F9] rounded py-3 px-2 gap-2">
                {orderedFields.map((item, index) => {
                  return (
                    <DraggableItem
                      key={item?.field?.id || item.displayName}
                      item={item}
                      index={index}
                      moveField={moveField}
                      onDragEnd={() => {
                        const localNewFieldOrder = {}
                        orderedFields.forEach((column, index) => {
                          if (column.reserved) return
                          localNewFieldOrder[column.field.id] = index
                        })

                        if (didOrderChange) {
                          setFieldsOrder({
                            fieldOrder: localNewFieldOrder,
                            isBuildingFields:
                              fieldType ===
                              BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING.name,
                          })
                        }
                      }}
                      onDeleteField={deleteField}
                      survey={survey}
                      onRenameField={renameField}
                      onChangeFieldDataType={changeFieldDataType}
                      onChangeFieldType={changeFieldType}
                      onRefresh={
                        selectedTab === 'buildings'
                          ? refreshBuildingValues
                          : refreshListingValues
                      }
                    />
                  )
                })}

                <div className="flex rounded py-2 px-3 bg-white border-solid border border-[#E0E0E0] gap-1.5 items-center">
                  <SquareIconButton disabled className="p-0">
                    <DragIcon className="opacity-50" />
                  </SquareIconButton>

                  <SquareIconButton disabled className="p-0">
                    <AddNewIconClass className="opacity-50" />
                  </SquareIconButton>

                  <form
                    className="grow"
                    onSubmit={async (e) => {
                      e.preventDefault()
                      handleAddField({
                        label: newField,
                        order: nextOrder,
                        type:
                          selectedTab === 'buildings' ? 'building' : 'space',
                      })
                    }}
                  >
                    <input
                      ref={newFieldRef}
                      type="text"
                      placeholder="Enter field name"
                      className="w-full px-0 outline-none focus:outline-none border-none text-sm leading-[22px] tracking-[0.1px] font-semi"
                      value={newField}
                      onChange={(e) => setNewField(e.target.value)}
                      onBlur={() => {
                        handleAddField({
                          label: newField,
                          order: nextOrder,
                          type:
                            selectedTab === 'buildings' ? 'building' : 'space',
                        })
                      }}
                    />
                  </form>

                  <SquareIconButton disabled className="opacity-50">
                    <DeleteIcon />
                  </SquareIconButton>
                </div>
              </div>
            </DndProvider>
          </div>
        </Fade>
      </Drawer>
    </Dialog>
  )
}

const DraggableItem = ({
  survey,
  item,
  index,
  moveField,
  onDragEnd,
  onDeleteField,
  onRenameField,
  onChangeFieldDataType,
  onChangeFieldType,
  onRefresh,
}) => {
  const [modalName, setModalName] = useState(null)
  const IconClass = getIconByDataTypeId(item.fieldDataType.id)
  const [newItem, setNewItem] = useState({ ...item })
  const isReserved = newItem.reserved
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const anchorRef = useRef(null)

  const handleHeaderMenuClick = () => {
    setAnchorEl(anchorRef.current)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }
  const handleClose = () => {
    closeMenu()
  }

  const onCancelChangeFieldDataType = () => {
    setNewItem({
      ...newItem,
      fieldDataType: item.fieldDataType,
    })
    setModalName(null)
  }

  const onCancelChangeFieldType = () => {
    setNewItem({
      ...newItem,
      fieldType: item.fieldType,
    })
    setModalName(null)
  }

  const handleRenameField = async () => {
    if (newItem.displayName.trim() !== item.displayName.trim()) {
      await onRenameField({
        id: item.field.id,
        newName: newItem.displayName,
      })

      SnackbarUtils.success(t('bulkEdit.toasts.fieldSaved'))
    }
  }

  const ref = useRef(null)
  const previewRef = useRef(null)
  const ItemTypes = {
    CARD: 'card',
  }

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.CARD, index, field: item },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      moveField(dragIndex, hoverIndex)
      // eslint-disable-next-line
      item.index = hoverIndex
    },
    drop() {
      onDragEnd()
    },
  })

  const opacity = isDragging ? 0 : 1
  drag(ref)
  drop(previewRef)
  preview(previewRef)

  return (
    <div
      className="flex rounded py-2 px-3 bg-white border-solid border border-[#E0E0E0] gap-1.5 items-center"
      ref={!isReserved ? previewRef : null}
      data-handler-id={!isReserved ? handlerId : null}
      style={{ opacity }}
    >
      <SquareIconButton
        tooltipText={isReserved ? 'Can’t move this field' : ''}
        disabled={isReserved}
        ref={ref}
      >
        <DragIcon
          className={clsx(
            !isReserved && 'cursor-grab',
            isReserved && 'opacity-50'
          )}
        />
      </SquareIconButton>

      <div ref={anchorRef}>
        <SquareIconButton
          onClick={() => {
            if (!isReserved) {
              handleHeaderMenuClick()
            }
          }}
          className="p-0"
          disabled={isReserved}
        >
          {isReserved ? <LogoIcon /> : <IconClass />}
        </SquareIconButton>
      </div>

      {isReserved ? (
        <Typography variant="bodyBold" className="grow px-1">
          {item.displayName}
        </Typography>
      ) : (
        <form
          className="grow"
          onSubmit={async (e) => {
            e.preventDefault()
            handleRenameField()
          }}
        >
          <input
            type="text"
            placeholder="Enter field name"
            className="w-full p-0 outline-none focus:outline-none border-none text-sm leading-[22px] tracking-[0.1px] font-semi cursor-pointer hover:bg-[#f3f3f3] px-1 py-[3px]"
            value={newItem.displayName}
            onChange={(e) => {
              const { value } = e.target

              setNewItem((currentValue) => ({
                ...currentValue,
                displayName: value,
              }))
            }}
            onBlur={() => {
              handleRenameField()
            }}
          />
        </form>
      )}

      <SquareIconButton
        tooltipText={isReserved ? 'Can’t delete this field' : ''}
        disabled={isReserved}
        className={clsx(isReserved && 'opacity-50')}
        onClick={() => {
          if (!isReserved) {
            setModalName('deleteField')
          }
        }}
      >
        <DeleteIcon />
      </SquareIconButton>

      {!isReserved ? (
        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          open={open}
          onClose={handleClose}
          className="w-[240px]"
          elevation={2}
          // Open bottom left
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              width: '240px',
              minWidth: '240px',
              maxHeight: '375px',
            },
          }}
        >
          {/* Menu section for the field data type */}
          {!isReserved && (
            <FieldDataTypeMenuSection
              classesIn={{
                fieldHeaderMenuSection: 'pt-3',
              }}
              onClick={(newFieldDataType) => {
                setNewItem({
                  ...newItem,
                  fieldDataType: newFieldDataType,
                })
                setModalName('changeFieldDataType')
                closeMenu()
              }}
              fieldDataTypeId={item.fieldDataType.id}
            />
          )}

          {/* Field types - space or building */}
          {!isReserved && [
            <div
              key="fieldTypeKey"
              className="text-[#666] pb-1.5 pt-5 px-3 items-center"
            >
              <Typography variant="h3" className="leading-3 font-med">
                Field Type
              </Typography>
            </div>,
            BULK_IMPORT_CONSTANTS.USER_SELECTABLE_FIELD_TYPES_ORDERED.map(
              (fieldType) => {
                const FieldIconComponent = fieldType.icon
                return (
                  <MenuItem
                    className="px-3 items-center"
                    disableGutters
                    key={fieldType.id}
                    onClick={() => {
                      setNewItem({
                        ...newItem,
                        fieldType,
                      })
                      setModalName('changeFieldType')
                      closeMenu()
                    }}
                  >
                    <FieldIconComponent className="mr-3 text-[#111]" />
                    <Typography color="textPrimary" variant="h3">
                      {fieldType.name}
                    </Typography>
                    {fieldType.id === item.fieldType.id && (
                      <MenuSelectedItemIcon className="ml-auto text-[#666]" />
                    )}
                  </MenuItem>
                )
              }
            ),
          ]}
        </Menu>
      ) : null}

      {/* FIXME: Putting this for now. Implement a better way after */}
      <DeleteFieldModal
        fieldName={item.displayName}
        surveyName={survey?.name}
        deleteSpaceField={() => {
          onDeleteField({ id: item.field.id })
        }}
        isBuildingField={
          item.fieldType.id === BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING.id
        }
        open={modalName === 'deleteField'}
        onClose={() => setModalName(null)}
        textOverride={`Are you sure you want to delete the ${item.displayName} field? This will also delete its data. This action cannot be undone.`}
      />

      <ConfirmModalComponent
        ModalComponentProps={{
          open: modalName === 'changeFieldDataType',
          onClose: onCancelChangeFieldDataType,
        }}
        onClose={onCancelChangeFieldDataType}
        onConfirm={async () => {
          await onChangeFieldDataType({
            id: newItem.field.id,
            newType: newItem.fieldDataType.id,
            newValue: null,
            optimistic: false,
          })
          setModalName(null)
          await onRefresh()
        }}
        title="Change Data Type"
        confirmButtonLabel="Confirm"
        text={`Are you sure you want to change ${newItem.displayName} from ${item.fieldDataType.name} to ${newItem.fieldDataType.name}? Doing so may cause some or all of the field’s data to be permanently erased.`}
      />

      <ConfirmModalComponent
        ModalComponentProps={{
          open: modalName === 'changeFieldType',
          onClose: onCancelChangeFieldType,
        }}
        onClose={onCancelChangeFieldType}
        onConfirm={() => {
          onChangeFieldType({
            id: newItem.field.id,
            newType: newItem.fieldType.templateType,
          })
          setModalName(null)
        }}
        title="Change Field Type"
        confirmButtonLabel="Confirm"
        text={`Are you sure you want to change the ${newItem.displayName} field type from ${item.fieldType.name} to ${newItem.fieldType.name}? Doing so will cause the field’s data to be permanently erased. This cannot be undone.`}
      />
    </div>
  )
}

export const cellComponentMap = {
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.ADDRESS.id]: AddressFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.NUMBER.id]: NumericFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.SIZE_SQFT.id]: NumericFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.CURRENCY_USD.id]: NumericFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.DATE.id]: DateFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.LIST.id]: ListSemicolonCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.MULTILINE_STRING.id]:
    MultilineStringCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.id]: StringCell,
}
