import { useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { isBroker } from '~/legacy/utils'
import { LEASE_DASHBOARD, LEASE_LOGIN, LEASE_PROJECTS } from '~/legacy/consts'

export function useRedirectNoPermission(user) {
  const history = useHistory()
  const location = useLocation()
  const redirected = useRef(false)
  const queryParams = new URLSearchParams(location.search)

  return () => {
    if (redirected.current) return
    redirected.current = true

    if (user?.loggedIn) {
      history.push(isBroker(user) ? LEASE_PROJECTS : LEASE_DASHBOARD)
      return
    }

    const redirect =
      queryParams.get('redirect') ||
      `${encodeURIComponent(location.pathname + (location.search || ''))}`

    history.push(`${LEASE_LOGIN}?redirect=${redirect}`)
  }
}

