// @ts-check
import DateFnsUtils from '@date-io/date-fns'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import React, { useEffect, useState } from 'react'
import { parseISO, format } from 'date-fns'
import SquareCheckbox from '~/legacy/components/buttons/SquareCheckbox'

export const INPUT_STYLE = 'p-2 rounded border border-solid border-gray-300'

const defaultOperator = { operator: 'range', value: '', maxValue: '' }
// Parse the existing value string into operator and values
const parseValue = (str) => {
  if (!str) return defaultOperator
  if (str.includes('-')) {
    const [min, max] = str.split('-')
    return { operator: 'range', value: min, maxValue: max }
  }
  const matches = str.match(/^([<>]=?|=)?(.+)$/)
  if (matches) {
    return { operator: matches[1] || '=', value: matches[2], maxValue: '' }
  }
  return defaultOperator
}

export const NumericFieldInput = ({ value = '', onChange }) => {
  const { value: minValue, maxValue } = parseValue(value)
  const [minInput, setMinInput] = useState(minValue)
  const [maxInput, setMaxInput] = useState(maxValue)

  useEffect(() => {
    setMinInput(minValue)
    setMaxInput(maxValue)
  }, [minValue, maxValue])

  const handleChange = (newOperator, newValue, newMaxValue = '') => {
    if (newOperator === 'range') {
      onChange(`${newValue}-${newMaxValue}`)
    } else {
      onChange(`${newOperator}${newValue}`)
    }
  }

  return (
    <div className="flex gap-2 min-w-0 flex-1">
      <div className="flex-1 flex gap-2 items-center min-w-0">
        <input
          type="number"
          value={minInput}
          onChange={(e) => {
            setMinInput(e.target.value)
            handleChange('range', e.target.value, maxInput)
          }}
          placeholder="Min"
          className={`min-w-0 flex-1 ${INPUT_STYLE}`}
        />
        <span className="whitespace-nowrap px-1">to</span>
        <input
          type="number"
          value={maxInput}
          onChange={(e) => {
            setMaxInput(e.target.value)
            handleChange('range', minInput, e.target.value)
          }}
          placeholder="Max"
          className={`min-w-0 flex-1 ${INPUT_STYLE}`}
        />
      </div>
    </div>
  )
}

export const DateRangeInput = ({ value = '', onChange }) => {
  // Parse the input value which could be either a string (old format) or an object (new format)
  const parseValue = (input) => {
    if (!input) return { startDate: null, endDate: null }

    // If it's already an object with the new format
    if (typeof input === 'object') {
      return {
        startDate: input.start ? parseISO(input.start) : null,
        endDate: input.end ? parseISO(input.end) : null,
      }
    }

    return { startDate: null, endDate: null }
  }

  const { startDate, endDate } = parseValue(value)

  const handleDateChange = ({ start, end }) => {
    if (!start && !end) {
      onChange({ startDate: null, endDate: null })
      return
    }

    const newStart = start ? format(start, 'yyyy-MM-dd') : null
    const newEnd = end ? format(end, 'yyyy-MM-dd') : null

    onChange({
      start: newStart,
      end: newEnd,
    })
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="flex gap-2 items-center min-w-0">
        <div className="flex-1 min-w-0">
          <DatePicker
            autoOk
            format="yyyy-MM-dd"
            fullWidth
            variant="inline"
            inputVariant="outlined"
            size="small"
            placeholder="Start"
            onChange={(newDate) => {
              handleDateChange({ start: newDate, end: endDate })
            }}
            value={startDate}
            TextFieldComponent={(props) => (
              <TextField {...props} label="Start" />
            )}
          />
        </div>
        <div className="flex-1 min-w-0">
          <DatePicker
            autoOk
            disableToolbar
            format="yyyy-MM-dd"
            fullWidth
            variant="inline"
            inputVariant="outlined"
            size="small"
            placeholder="End"
            onChange={(newDate) => {
              handleDateChange({ start: startDate, end: newDate })
            }}
            value={endDate}
            TextFieldComponent={(props) => <TextField {...props} label="End" />}
          />
        </div>
      </div>
    </MuiPickersUtilsProvider>
  )
}

export const CustomFieldInput = ({ field, value, onChange, columnStats }) => {
  const dataTypeToInputType = {
    1: 'text', // String
    3: 'number', // Number
    5: 'number', // SIZE_SQFT
    7: 'date', // Date
  }

  const inputType = dataTypeToInputType[field.data_type] || 'text'

  if (inputType === 'number') {
    return <NumericFieldInput value={value} onChange={onChange} />
  }

  if (inputType === 'date') {
    return <DateRangeInput value={value} onChange={onChange} />
  }

  if (inputType === 'text') {
    return (
      <StringFieldInput
        field={field}
        value={value}
        onChange={onChange}
        distinctValues={columnStats?.distinctValues}
        distinctCount={columnStats?.distinctCount}
      />
    )
  }

  return (
    <input
      type={inputType}
      value={value || ''}
      onChange={(e) => onChange(e.target.value)}
      className={`flex-1 ${INPUT_STYLE}`}
    />
  )
}

const chooseElementByCardinality = (distinctCount) => {
  if (distinctCount <= 5) return 'checkboxes'
  return 'combobox'
}

const StringFieldInput = ({
  field,
  value,
  onChange,
  distinctValues = new Set(),
  distinctCount = 0,
}) => {
  const elementType = chooseElementByCardinality(distinctCount)
  const options = Array.from(distinctValues)

  switch (elementType) {
    case 'checkboxes':
      return (
        <CheckboxGroup
          field={field}
          value={value}
          onChange={onChange}
          options={options}
        />
      )
    case 'combobox':
      return (
        <Combobox
          field={field}
          value={value}
          onChange={onChange}
          options={options}
        />
      )
    default:
      return <StandardInput value={value} onChange={onChange} />
  }
}

const CheckboxGroup = ({ field, value, onChange, options }) => {
  // Convert input to array of selected values
  let selectedValues = new Set()
  if (value) {
    if (Array.isArray(value)) {
      selectedValues = new Set(value)
    } else {
      selectedValues = new Set([value]) // Single string value
    }
  }

  const handleChange = (opt, checked) => {
    const newValues = new Set(selectedValues)
    if (checked) {
      newValues.add(opt)
    } else {
      newValues.delete(opt)
    }

    onChange(Array.from(newValues))
  }

  return (
    <div className="space-y-1" data-field-id={field.id}>
      {options.map((opt) => (
        <label key={opt} className="flex items-center gap-2 cursor-pointer">
          <SquareCheckbox
            checked={selectedValues.has(opt)}
            edge={false}
            className="text-[#111]"
            onChange={(e) => handleChange(opt, e.target.checked)}
          />
          <span>{opt}</span>
        </label>
      ))}
    </div>
  )
}

const Combobox = ({ field, value, onChange, options }) => {
  let autoCompleteValue = !value ? [] : value
  if (!Array.isArray(autoCompleteValue)) {
    autoCompleteValue = [value]
  }

  return (
    <Autocomplete
      id={`field-autocomplete-${field.id}`}
      multiple
      value={autoCompleteValue}
      onChange={(_, newValue) => {
        onChange(newValue)
      }}
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <SquareCheckbox className="mr-2" checked={selected} />
          {option}
        </React.Fragment>
      )}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            label={field.label}
            size="small"
            placeholder="Search values"
          />
        )
      }}
    />
  )
}

export const StandardInput = ({ value, onChange, ...props }) => {
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <input
      type="text"
      value={inputValue}
      onChange={(e) => {
        const newValue = e.target.value
        setInputValue(newValue)
        onChange(newValue)
      }}
      placeholder={props.placeholder}
      className={`w-full ${INPUT_STYLE} ${props.className || ''}`}
      maxLength={props.maxLength}
      {...props}
    />
  )
}

export const LatLngInput = ({ lat, lng, onLatChange, onLngChange }) => {
  return (
    <div className="flex gap-2">
      <div className="flex-1">
        <input
          type="number"
          value={lat || ''}
          onChange={(e) => onLatChange(e.target.value)}
          placeholder="Latitude"
          step="0.000001"
          className={INPUT_STYLE}
        />
      </div>
      <div className="flex-1">
        <input
          type="number"
          value={lng || ''}
          onChange={(e) => onLngChange(e.target.value)}
          placeholder="Longitude"
          step="0.000001"
          className={INPUT_STYLE}
        />
      </div>
    </div>
  )
}
