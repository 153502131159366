// @ts-check
import { useState, useEffect } from 'react'
import useSWR from 'swr'
import { surveyApi } from '~/legacy/fetchApi'

// Fetch fresh bdp data from our backend
const fetchSurvey = async (surveyId, page, isAnonymous) => {
  return surveyApi
    .getSurvey({ surveyId, page, skipAuth: isAnonymous })
    .then(([, response]) => response)
}

// Our bdp data in SWR
export const SWR_SURVEY = 'survey'

// Hook to manage the raw Survey Building in SWR as well as format the raw Survey Building.
export const useSurveySelector = (
  surveyBuildingId = null,
  page = null,
  isAnonymous = false
) => {
  // Async wrapper for fetching our bdp data from backend
  const swrArgs = []
  if (surveyBuildingId) {
    swrArgs.push(async () => fetchSurvey(surveyBuildingId, page, isAnonymous))
  }

  // SWR bdp data
  const { data: rawSurvey, error, mutate } = useSWR(SWR_SURVEY, ...swrArgs)
  // Whenever we change/update the bdp data via api, format it
  const [survey, setSurvey] = useState(rawSurvey)

  useEffect(() => {
    if (rawSurvey) {
      setSurvey(rawSurvey)
    } else {
      setSurvey(null)
    }
  }, [rawSurvey])

  return {
    survey,
    mutate,
    loading: !survey,
    error,
  }
}
