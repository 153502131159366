// @ts-check
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Redirect, useParams } from 'react-router'
import clsx from 'clsx'
import {
  Backdrop,
  Breadcrumbs,
  Container,
  Tab,
  Tabs,
  Tooltip,
  useTheme,
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Api from 'rest-fetcher-redux'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import {
  Typography,
  SquareIconButton,
  TextButton,
  Button,
  useBulkImport,
  Modal,
  MODALS,
  BulkImportCsvReader,
  Loading,
  TextLink,
} from '~/legacy/components'
import { AddToSurveyModal } from '~/legacy/components/modalComponents/AddToSurveyModal'
import { BuildingsTab } from './buildings-tab'
import { SpacesTab } from './spaces-tab'
import {
  UploadIcon,
  AISparkIcon,
  MoreIcon,
  PlusIcon,
  TableSettingsIcon,
} from '~/legacy/components/svgs'
import { ToggleFiltersButton } from '~/legacy/components/search-bar/search-controls'
import { SearchBarDrawer } from '~/legacy/components/SearchBar'
import { useColumnStats } from '~/legacy/pages/Surveys/Survey/bulk-edit-collection/hooks/useColumnStats'
import { AddBuildingToSurveyModal2 } from '~/legacy/components/modalComponents/AddBuildingToSurveyModal2'
import { ManageFieldsDrawer } from '~/legacy/pages/Surveys/Survey/bulk-edit-collection/table-components'
import SurveyBulkImport from '~/legacy/pages/Surveys/Survey/SurveyBulkImport'
import {
  BULK_IMPORT_CONSTANTS,
  BULK_IMPORT_HELPERS,
} from '~/legacy/utils/bulkImportUtils'
import FileExtractorInput from '~/legacy/components/FileExtractorInput'
import { parseGoogleAddressComponents } from '~/legacy/utils/mapHelpers'
import { getSearchAddressForDisplay } from '~/legacy/utils/addressHelpers'
import { useBulkEdit } from '~/legacy/pages/Surveys/Survey/bulk-edit-collection/use-bulk-edit'
import { useSearch } from '~/legacy/components/search-bar/use-search'

const containerClasses = {
  root: 'flex flex-col p-0 pb-8 max-w-full w-full overflow-auto',
}

export function BulkEditCollection() {
  const databasesEnabled = useFeatureFlagEnabled('lu_databases')

  const { id: surveyId } = useParams()
  const {
    survey,
    isLoading,
    buildingValues,
    listingValues,
    buildingColumns,
    spaceColumns,
    handleBuildingValueChange,
    handleListingValueChange,
    handleAddSpace,
    surveyBuildings,
    surveyListings,
    getFilteredBuildings,
    getSortedBuildings,
    getFilteredListings,
  } = useBulkEdit({
    surveyId,
    kind: 'database',
    enabled: Boolean(surveyId),
  })

  const { tab: selectedTab, setQuery } = useSearch()

  const stickyTabsRef = React.createRef()
  const mainContainerRef = useRef(null)
  const [isSticky, setIsSticky] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [addToSurveyModalOpen, setAddToSurveyModalOpen] = useState(false)
  const [modalName, setModalName] = useState(null)

  const selectedListingIds = useMemo(
    () =>
      surveyListings
        .filter((sl) => selectedRows.includes(sl.listing.building.id))
        .map((sl) => sl.listing.id),
    [selectedRows, surveyListings]
  )

  const hasBuildings = (surveyBuildings?.length || 0) > 0

  const filteredBuildings = useMemo(() => {
    return getFilteredBuildings()
  }, [getFilteredBuildings])

  const sortedBuildings = useMemo(() => {
    return getSortedBuildings(filteredBuildings)
  }, [filteredBuildings, getSortedBuildings])

  const filteredListings = useMemo(() => {
    return getFilteredListings()
  }, [getFilteredListings])

  const buildingColumnStats = useColumnStats({
    columns: buildingColumns,
    values: buildingValues,
    loading: isLoading,
  })

  const listingColumnStats = useColumnStats({
    columns: spaceColumns,
    values: listingValues,
    loading: isLoading,
  })

  useEffect(() => {
    // Only scroll to top when changing tabs
    if (selectedTab && mainContainerRef.current) {
      mainContainerRef.current.scrollTo(0, 0)
    }
  }, [selectedTab])

  useEffect(() => {
    const cachedRef = stickyTabsRef.current
    if (!cachedRef) {
      return () => {}
    }
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(e.intersectionRatio < 1)
      },
      { threshold: [1] }
    )
    observer.observe(cachedRef)

    return () => observer.unobserve(cachedRef)
  }, [stickyTabsRef])

  // FIXME: Import stuff. At some point we need to improve this.
  // for now just duplicating the same behavior as BrokerSurvey
  const [userAcceptedFieldMatches, setUserAcceptedFieldMatches] =
    useState(false)
  const [bulkImportFieldMatchModalOpen, setBulkImportFieldMatchModalOpen] =
    useState(false)
  const csvLinkRef = useRef(null)
  const pdfLinkRef = useRef(null)
  const [backdropOpen, setBackdropOpen] = useState(false)
  const theme = useTheme()
  const backDropZIndex = theme.zIndex.drawer + 1

  const {
    setRawCsvHeaders,
    csvHeaderIndexLookup,
    loadedListingsFromCsv,
    setLoadedListingsFromCsv,
    loadedListingsFormatted,
    setLoadedListingsFormatted,
    mappedStandardFields,
    setMappedStandardFields,
    loadedListingFieldsObj,
    setLoadedListingFieldsObj,
    orderedListingFields,
    userMatchableCsvHeaders,
    validatedListings,
    setValidatedListings,
    removedHeaderIndices,
    setRemovedHeaderIndices,
    uploadedCsvFile,
    setUploadedCsvFile,
  } = useBulkImport({ withNewCustomFields: true })

  // After loading the listings from the CSV, go through each and try to fetch/populate its address
  useEffect(() => {
    if (loadedListingsFromCsv && csvHeaderIndexLookup) {
      const buildings = loadedListingsFromCsv
      const indexFormattedListingData = []

      // Create an object of the listing/building values. We key off of the index of the header in the csv, rather than
      // the string itself, to handle duplicates / changing names, etc.
      // ie: {0: '18 Tremont Street', ...}
      buildings.forEach((building) => {
        const newCsvFieldArray = {}
        Object.entries(building).forEach(([headerString, value]) => {
          newCsvFieldArray[csvHeaderIndexLookup[headerString]] = value
        })
        indexFormattedListingData.push(newCsvFieldArray)
      })

      // Now we need to google verify the addresses. Let's make a single request for all of the buildings,
      // and then supplement our listing/building info with the google address values, and set state/save
      const raw_addresses = buildings.map((building) =>
        getSearchAddressForDisplay(building)
      )

      Api.verifyAddresses({ body: { raw_addresses } }).then((apiResults) => {
        let finalResults = []

        // We've got the google address data, now build our result set. Make sure the number of listings we have and the
        //   number of address results are the same, else, things won't line up...
        if (apiResults && apiResults.results) {
          const { results } = apiResults
          if (
            results &&
            results.length &&
            results.length === buildings.length
          ) {
            results.forEach((result, index) => {
              if (
                result &&
                result.AUTOCOMPLETE_RESULT &&
                result.GEOCODE_RESULT
              ) {
                // Format/extract the google data we need

                const addressComponents = parseGoogleAddressComponents(
                  result.GEOCODE_RESULT.address_components,
                  result.GEOCODE_RESULT.geometry
                )
                const newAddressValues = {
                  ...addressComponents,
                  latitude: result.GEOCODE_RESULT.geometry.location.lat,
                  longitude: result.GEOCODE_RESULT.geometry.location.lng,
                }

                // Add the processed google address info into the result
                finalResults.push({
                  ...indexFormattedListingData[index],
                  [BULK_IMPORT_CONSTANTS.FIELDS.ADDRESS.index]: {
                    value: {
                      GOOGLE_ADDRESS: { ...newAddressValues },
                      GOOGLE_AUTOCOMPLETE_RESULTS: {
                        ...result.AUTOCOMPLETE_RESULT,
                      },
                    },
                  },
                })
              } else {
                finalResults.push({ ...indexFormattedListingData[index] })
              }
            })
          } else {
            finalResults = [...indexFormattedListingData]
          }
        } else {
          finalResults = [...indexFormattedListingData]
        }
        setLoadedListingsFormatted(finalResults)
        setBackdropOpen(false)
      })
    }
  }, [loadedListingsFromCsv, csvHeaderIndexLookup])

  // When the user has accepted the field matchings, and we've loaded the listings
  if (
    loadedListingsFormatted &&
    orderedListingFields &&
    userAcceptedFieldMatches
  ) {
    return (
      <SurveyBulkImport
        survey={survey}
        removedHeaderIndices={removedHeaderIndices}
        setRemovedHeaderIndices={setRemovedHeaderIndices}
        validatedListings={validatedListings}
        setValidatedListings={setValidatedListings}
        orderedListingFields={orderedListingFields}
        csvHeaderIndexLookup={csvHeaderIndexLookup}
        loadedListingFieldsObj={loadedListingFieldsObj}
        setLoadedListingFieldsObj={setLoadedListingFieldsObj}
        mappedStandardFields={mappedStandardFields}
        setMappedStandardFields={setMappedStandardFields}
        uploadedCsvFile={uploadedCsvFile}
      />
    )
  }

  if (isLoading) {
    return (
      <div className="h-full flex justify-center items-center">
        <Loading isLoading size={40} />
      </div>
    )
  }

  if ((survey && survey.kind !== 'database') || !databasesEnabled) {
    return <Redirect to="/" />
  }

  return (
    <Container
      maxWidth={false}
      classes={containerClasses}
      ref={mainContainerRef}
    >
      <div
        className={clsx(
          'sticky px-8 top-0 left-0 z-[3] bg-white h-[54px] flex items-center justify-between shadow-border',
          isSticky &&
            'after:content-[""] after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[1px] after:bg-[#E0E0E0] after:z-[-1]'
        )}
        ref={stickyTabsRef}
      >
        {/* FIXME: This is a hack to avoid showing the table content behing the sticky header */}
        <div
          className={clsx(
            'absolute h-4 bg-white left-8 right-0 top-[54px] z-[-1]',
            isSticky ? 'block' : 'hidden'
          )}
        />

        <div className="flex grow basis-[0%]">
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon className="mt-0.5" fontSize="small" />}
          >
            <TextLink
              color="inherit"
              onClick={() => {
                window.location.href = '/home?tab=databases'
              }}
              variant="h3"
            >
              Databases
            </TextLink>
            <Typography variant="bodyBold">{survey?.name}</Typography>
          </Breadcrumbs>
        </div>

        <Tabs
          className="h-full"
          classes={{ flexContainer: 'h-[54px]' }}
          value={selectedTab}
          onChange={(_, value) => {
            setQuery({ tab: value })
          }}
          indicatorColor="primary"
        >
          <Tab
            className="min-w-[unset] opacity-100"
            value="buildings"
            label="Buildings"
          />

          {hasBuildings ? (
            <Tab
              className="min-w-[unset] opacity-100"
              value="spaces"
              label="Spaces"
              disabled={!hasBuildings}
            />
          ) : (
            <Tooltip
              title={!hasBuildings ? 'Add a building first' : ''}
              disableFocusListener
              disableTouchListener
              classes={{
                tooltipPlacementBottom: 'my-2 mx-0',
              }}
            >
              <span>
                <Tab
                  className="min-w-[unset] opacity-100 h-[54px]"
                  value="spaces"
                  label="Spaces"
                  disabled={!hasBuildings}
                />
              </span>
            </Tooltip>
          )}
        </Tabs>

        <div className="flex items-center gap-2 grow basis-[0%] justify-end">
          {selectedRows.length ? (
            <TextButton
              variant="text"
              startIcon={<PlusIcon />}
              onClick={() => {
                setAddToSurveyModalOpen(true)
              }}
            >
              Add to Survey
            </TextButton>
          ) : null}

          <SquareIconButton
            tooltipText="Import with CSV"
            onClick={() => csvLinkRef.current.click()}
          >
            <UploadIcon />
          </SquareIconButton>

          <SquareIconButton
            tooltipText="Import with AI"
            onClick={() => pdfLinkRef.current.click()}
          >
            <AISparkIcon />
          </SquareIconButton>

          <SquareIconButton tooltipText="More actions">
            <MoreIcon />
          </SquareIconButton>
        </div>
      </div>

      <div className="flex justify-between bg-white sticky top-[54px] left-0 w-full z-[2]">
        <TableControls
          isSticky={isSticky}
          selectedTab={selectedTab}
          surveyBuildings={sortedBuildings}
          surveyListings={filteredListings}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          setModalName={setModalName}
        />
      </div>

      <SearchBarDrawer
        columnStats={
          selectedTab === 'buildings' ? buildingColumnStats : listingColumnStats
        }
        isVisible={showFilters}
      />

      {selectedTab === 'buildings' ? (
        <BuildingsTab
          buildingColumns={buildingColumns}
          buildingValues={buildingValues}
          surveyBuildings={sortedBuildings}
          onValueChange={handleBuildingValueChange}
          showFilters={showFilters}
          selectedRows={selectedRows}
          setModalName={setModalName}
          onRowSelection={(buildingId) => {
            setSelectedRows((current) => {
              return current.includes(buildingId)
                ? current.filter((id) => id !== buildingId)
                : [...current, buildingId]
            })
          }}
          onImportClick={(type) => {
            if (type === 'csv') {
              csvLinkRef.current.click()
            }

            if (type === 'pdf') {
              pdfLinkRef.current.click()
            }
          }}
        />
      ) : null}

      {selectedTab === 'spaces' ? (
        <SpacesTab
          spaceColumns={spaceColumns}
          surveyBuildings={sortedBuildings}
          surveyListings={filteredListings}
          listingValues={listingValues}
          onValueChange={handleListingValueChange}
          onAddSpace={handleAddSpace}
          showFilters={showFilters}
        />
      ) : null}

      {/* FIXME: Easy way to make the modal state to clear after closing */}
      {addToSurveyModalOpen && (
        <AddToSurveyModal
          open={addToSurveyModalOpen}
          onClose={() => setAddToSurveyModalOpen(false)}
          survey={survey}
          selectedListingIds={selectedListingIds}
        />
      )}

      {modalName === 'addBuilding' ? (
        <AddBuildingToSurveyModal2
          surveyId={survey.id}
          open={modalName === 'addBuilding'}
          onClose={() => setModalName(null)}
          onSuccess={() => {
            document
              .querySelector('[data-id="add-building-row"]')
              .scrollIntoView({
                behavior: 'smooth',
              })
          }}
        />
      ) : null}

      {modalName === 'manageFields' ? (
        <ManageFieldsDrawer
          open={modalName === 'manageFields'}
          fields={selectedTab === 'buildings' ? buildingColumns : spaceColumns}
          selectedTab={selectedTab}
          onClose={() => setModalName(null)}
        />
      ) : null}

      <Modal
        content={MODALS.BULK_IMPORT_FIELD_MATCH}
        open={bulkImportFieldMatchModalOpen}
        onClose={() => {
          setUserAcceptedFieldMatches(true)
          setBulkImportFieldMatchModalOpen(false)
        }}
        disableBackDropClickClose
        childProps={{
          csvFields: userMatchableCsvHeaders,
          // Fields from the CSV, matched already
          fieldsWithMatches: loadedListingFieldsObj,
          setFieldsWithMatches: (f) => {
            setLoadedListingFieldsObj(f)
          },
          // The fields that we want mappings for
          mappedStandardFields,
          setMappedStandardFields,
          orderedListingFields,
          loadedListingFieldsObj,
          setLoadedListingFieldsObj: (f) => {
            setLoadedListingFieldsObj(f)
          },
        }}
      />

      <BulkImportCsvReader
        surveyId={surveyId}
        linkRef={csvLinkRef}
        setBackdropOpen={setBackdropOpen}
        setUploadedCsvFile={setUploadedCsvFile}
        setLoadedListingsFromCsv={setLoadedListingsFromCsv}
        setRawCsvHeaders={setRawCsvHeaders}
        setBulkImportFieldMatchModalOpen={setBulkImportFieldMatchModalOpen}
      />

      {/* Loading backdrop for loading the CSV listings after the user selects the csv */}
      <Backdrop
        className="text-white"
        style={{ zIndex: backDropZIndex }}
        open={backdropOpen && !bulkImportFieldMatchModalOpen}
      >
        <div className="flex flex-col items-center">
          <Loading color="inherit" isLoading />
          <Typography className="text-lg">Processing file</Typography>
        </div>
      </Backdrop>

      {/* 
        FIXME: Doing the label click hack because I'dont want to spend much time
        making the label itself look like the button.
      */}
      <label ref={pdfLinkRef}>
        <FileExtractorInput
          onExtracted={(results) => {
            BULK_IMPORT_HELPERS.onCsvFileLoaded(
              results,
              setLoadedListingsFromCsv,
              setRawCsvHeaders,
              setBulkImportFieldMatchModalOpen
            )
          }}
          setParentLoading={setBackdropOpen}
          surveyId={surveyId}
          setUploadedCsvFile={setUploadedCsvFile}
        />
      </label>
    </Container>
  )
}

const TableControls = ({
  selectedTab,
  isSticky,
  surveyBuildings,
  surveyListings,
  showFilters,
  setShowFilters,
  setModalName,
}) => {
  const buildingCountString =
    (surveyBuildings || []).length === 1
      ? '1 Building'
      : `${(surveyBuildings || []).length} Buildings`

  const listingCountString =
    surveyListings.length === 1 ? '1 Space' : `${surveyListings.length} Spaces`

  return (
    <>
      <div
        className={clsx(
          'flex justify-between bg-white px-8 w-full items-center h-[68px]',
          isSticky && 'sticky top-[54px] z-[2]'
        )}
      >
        <div className="flex items-center justify-center">
          <Typography
            className="my-auto leading-7 font-semibold"
            variant="boldText"
          >
            {selectedTab === 'buildings'
              ? buildingCountString
              : listingCountString}
          </Typography>

          <div className="h-7 w-[1px] bg-[#D9D9D9] mx-4" />

          <ToggleFiltersButton
            showFilters={showFilters}
            setShowFilters={setShowFilters}
          />
        </div>

        <div className="flex items-center gap-2">
          <button
            className="py-0 pl-2 pr-3 h-8 border border-[#E0E0E0] border-solid text-[#111111] mb-0 rounded bg-white flex items-center gap-1 cursor-pointer"
            onClick={() => setModalName('manageFields')}
          >
            <TableSettingsIcon style={{ rotate: '90deg' }} />
            <Typography variant="boldText">Manage fields</Typography>
          </button>

          {selectedTab === 'buildings' ? (
            <Button
              color="primary"
              className="py-0 px-4 h-8 m-auto mr-[1px]"
              onClick={() => setModalName('addBuilding')}
              shrinkButton
            >
              Add Building
            </Button>
          ) : null}
        </div>
      </div>
    </>
  )
}
