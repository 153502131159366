// @ts-check
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LogoIcon } from '~/legacy/components/svgs'
import { useSurveyListingCustomFieldSelector } from '~/legacy/utils/hooks/useSurveyListingCustomFieldSelector'
import { SnackbarUtils } from '~/legacy/utils'
import { setSurveyListings } from '~/legacy/store/actions/viewSurvey'
import { listingApi, surveyBuildingApi } from '~/legacy/fetchApi'
import {
  BULK_IMPORT_CONSTANTS,
  getDataTypeByDisplayName,
  BULK_IMPORT_HELPERS,
  getModelFieldNameByDisplayName,
} from '~/legacy/utils/bulkImportUtils'

const SPACE_RESERVED_FIELDS = ['Space Name', 'Space Notes']

/**
 *
 * @param {Object} options
 * @param {Number} [options.surveyId]
 * @param {'survey' | 'database'} [options.kind]
 * @param {Array<Object>} [options.listingCustomFields]
 */
const useSurveyCustomFields = ({
  surveyId,
  listingCustomFields,
  kind = 'survey',
}) => {
  const dispatch = useDispatch()
  const surveyListingsRedux = useSelector(
    (state) => state.pages.viewSurvey.surveyListings
  )

  const {
    customFieldValues: listingValues,
    isLoading: isListingValuesLoading,
    mutate: refreshListingValues,
    mutateChangeListingCustomFieldValue: updateListingCustomField,
    mutateCreateListingCustomFieldValue: createListingCustomField,
  } = useSurveyListingCustomFieldSelector({
    surveyId,
    kind,
    enabled: Boolean(surveyId && kind),
  })

  const spaceColumns = useMemo(() => {
    return SPACE_RESERVED_FIELDS.map((field, index) => {
      const fieldType = BULK_IMPORT_CONSTANTS.FIELD_TYPES.SPACE
      const fieldDataType = getDataTypeByDisplayName(field)
      const modelName = getModelFieldNameByDisplayName(field)

      return {
        displayName: field,
        index,
        fieldType,
        fieldDataType,
        reserved: true,
        modelName,
        icon: LogoIcon,
      }
    }).concat(
      listingCustomFields.map((field, index) => {
        const fieldType = BULK_IMPORT_CONSTANTS.FIELD_TYPES.SPACE
        const fieldDataType = BULK_IMPORT_HELPERS.getFieldDataTypeById(
          field.data_type
        )
        const newIndex = index + SPACE_RESERVED_FIELDS.length

        return {
          displayName: field.label,
          index: newIndex,
          fieldType,
          fieldDataType,
          reserved: false,
          field,
          modelName: null,
          icon: fieldDataType.icon,
        }
      })
    )
  }, [listingCustomFields])

  const handleListingValueChange = ({
    entityId: listingId,
    header,
    cell,
    value,
  }) => {
    const isCustomField = !header.reserved
    const isCreatingNewValue = !cell.id

    if (isCustomField) {
      if (isCreatingNewValue) {
        createListingCustomField({
          surveyId,
          listingId,
          customFieldId: header.field.id,
          newValue: value,
        })
      } else {
        updateListingCustomField({
          id: cell.id,
          newValue: value,
        })
      }
    }

    if (!isCustomField) {
      const updatedListing = {
        [header.modelName]: value,
      }

      listingApi
        .updatePartial({
          listingId,
          partial: updatedListing,
        })
        .then(([, responseListing]) => {
          const newListings = surveyListingsRedux.map((sl) => {
            if (sl.listing.id === listingId) {
              return {
                ...sl,
                listing: {
                  ...sl.listing,
                  ...responseListing,
                },
              }
            }
            return { ...sl }
          })
          dispatch(setSurveyListings(newListings))
        })
        .catch((error) => {
          console.error(error)
          SnackbarUtils.error(`Error updating ${header.displayName}`)
          dispatch(setSurveyListings(surveyListingsRedux))
        })
    }
  }

  const handleAddSpace = (surveyBuildingId) => {
    surveyBuildingApi
      .createSurveyListing({
        surveyBuildingId,
      })
      .then(([, responseSurveyBuilding]) => {
        const { building, survey_listings: responseSurveyListings } =
          responseSurveyBuilding.data
        const surveyListingsIds = surveyListingsRedux.map((sl) => sl.id)

        const listingsToAdd = responseSurveyListings
          .filter((sl) => !surveyListingsIds.includes(sl.id))
          .map((sl) => ({
            ...sl,
            listing: {
              ...sl.listing,
              building,
            },
          }))

        dispatch(setSurveyListings([...surveyListingsRedux, ...listingsToAdd]))
      })
  }

  return {
    listingValues,
    spaceColumns,
    handleListingValueChange,
    handleAddSpace,
    isListingValuesLoading,
    refreshListingValues,
  }
}

const useListingCustomFields = useSurveyCustomFields

export { useListingCustomFields }
