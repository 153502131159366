// @ts-check
import { fetchApi } from './rootApi'

const API_ROOT = 'survey_building_custom_fields'

// TODO: add tracking for each of these

export const surveyBuildingCustomFieldApi = {
  list: async ({ surveyId, buildingId }) => {
    const queryParams = new URLSearchParams()

    if (surveyId) {
      queryParams.append('surveyId', surveyId)
    }
    if (buildingId) {
      queryParams.append('buildingId', buildingId)
    }

    return fetchApi({
      endpoint: `${API_ROOT}/?${queryParams}`,
      fetchArgs: {
        method: 'GET',
      },
    })
  },

  createFieldValue: async ({
    surveyId,
    buildingId,
    customFieldId,
    value = '',
  }) => {
    const body = {}
    if (surveyId) body.surveyId = surveyId
    if (buildingId) body.buildingId = buildingId
    if (customFieldId) body.customFieldId = customFieldId
    if (value !== undefined) body.value = value

    return fetchApi({
      endpoint: `${API_ROOT}/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify(body),
      },
    })
  },

  updateFieldValue: async ({ surveyBuildingCustomFieldId, value }) => {
    const body = {}
    if (value !== undefined) body.new_value = value

    return fetchApi({
      endpoint: `${API_ROOT}/${surveyBuildingCustomFieldId}/change_field_value/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify(body),
      },
    })
  },
}
