// @ts-check
import React from 'react'
import { useApiHelper } from '~/legacy/utils/hooks'
import { uploadFile } from '~/legacy/utils'

const FileExtractorInput = ({
  onExtracted,
  surveyId,
  setParentLoading = () => {},
  setUploadedCsvFile = () => {},
  onError,
  onWarn,
  fileSizeMb,
}) => {
  const apiHelper = useApiHelper()

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    const MAX_FILE_SIZE = fileSizeMb ? fileSizeMb * 1048576 : 0 // Convert MB to bytes if specified

    if (MAX_FILE_SIZE > 0 && selectedFile.size > MAX_FILE_SIZE) {
      const errorMessage = `File size exceeds maximum limit of ${fileSizeMb}MB`
      console.error(errorMessage)
      if (onError) {
        onError(errorMessage)
      }
      return
    }

    handleSubmit(selectedFile).catch((error) => {
      console.error('Error processing property data:', error)
      if (onError) {
        onError(error.message)
      }
    })
  }

  const handleSubmit = async (selectedFile) => {
    if (!selectedFile) {
      const error = new Error('No file selected')
      if (onError) onError(error.message)
      return
    }

    setParentLoading(true)

    // Upload the CSV file to S3 (via webapp)
    uploadFile(
      selectedFile,
      'bulk_import_pdf_file',
      { survey_id: surveyId },
      true
    ).then((result) => {
      if (result && result.data && result.data.file) {
        // FIXME: I'm reusing the same state, but would be better to have a separate variable
        // This comes from BulkImportHooks.jsx
        setUploadedCsvFile(result.data.file)
      }
    })

    try {
      // Upload the file
      const uploadResult = await apiHelper.uploadPropertyAsset(selectedFile)
      const { filename } = uploadResult

      // Extract data from the uploaded file
      const extractedData = await apiHelper.extractPropertyData({ filename })

      // Handle any extraction failures
      if (
        extractedData.failures &&
        extractedData.failures.length > 0 &&
        onWarn
      ) {
        onWarn(extractedData.failures)
      }

      onExtracted(extractedData.results)
    } catch (error) {
      console.error('Error processing property data:', error)
      if (onError) {
        onError(error.message)
      }
      throw error
    } finally {
      setParentLoading(false)
    }
  }

  return (
    <input
      accept=".pdf,.jpg,.jpeg,.png"
      style={{ display: 'none' }}
      id="file-input"
      type="file"
      onChange={handleFileChange}
    />
  )
}

export default FileExtractorInput
