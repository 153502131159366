// @ts-check
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Api from 'rest-fetcher-redux'
import { setSurveyBuildings } from '~/legacy/store/actions/viewSurvey'
import {
  useSurveyBuildingCustomFieldSelector,
  SnackbarUtils,
} from '~/legacy/utils'
import { LogoIcon } from '~/legacy/components/svgs'
import { buildingApi } from '~/legacy/fetchApi'

import {
  BULK_IMPORT_CONSTANTS,
  getDataTypeByDisplayName,
  BULK_IMPORT_HELPERS,
  getModelFieldNameByDisplayName,
} from '~/legacy/utils/bulkImportUtils'

const BUILDING_RESERVED_FIELDS = [
  'Address',
  'Building Name',
  'Building Notes',
  'Amenities',
]

/**
 * @param {Object} options
 * @param {Number} [options.surveyId]
 * @param {'survey' | 'database'} [options.kind]
 * @param {Array<Object>} [options.buildingCustomFields]
 *
 * */
export const useBuildingCustomFields = ({
  surveyId,
  kind = 'survey',
  buildingCustomFields,
}) => {
  const dispatch = useDispatch()
  const surveyBuildingsRedux = useSelector(
    (state) => state.pages.viewSurvey.surveyBuildings
  )

  const {
    customFieldValues: buildingValues,
    isLoading: isBuildingValuesLoading,
    mutate: refreshBuildingValues,
    mutateChangeBuildingCustomFieldValue: updateBuildingCustomField,
    mutateCreateBuildingCustomFieldValue: createBuildingCustomField,
  } = useSurveyBuildingCustomFieldSelector({
    surveyId,
    kind,
    enabled: Boolean(surveyId && kind),
  })

  const buildingColumns = useMemo(() => {
    const fieldType = BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING
    const totalFields =
      BUILDING_RESERVED_FIELDS.length + buildingCustomFields.length

    const allFields = BUILDING_RESERVED_FIELDS.map((field, index) => {
      const fieldDataType = getDataTypeByDisplayName(field)
      const modelName = getModelFieldNameByDisplayName(field)

      const isAmenities = modelName === 'amenities'

      return {
        displayName: field,
        // Put amenities as the last column
        index: isAmenities ? totalFields - 1 : index,
        fieldType,
        fieldDataType,
        reserved: true,
        modelName,
        icon: LogoIcon,
      }
    }).concat(
      buildingCustomFields.map((field, index) => {
        const fieldDataType = BULK_IMPORT_HELPERS.getFieldDataTypeById(
          field.data_type
        )
        const newIndex = index + BUILDING_RESERVED_FIELDS.length - 1

        return {
          displayName: field.label,
          index: newIndex,
          fieldType,
          fieldDataType,
          reserved: false,
          field,
          modelName: null,
          icon: fieldDataType.icon,
        }
      })
    )

    return allFields.sort((a, b) => a.index - b.index)
  }, [buildingCustomFields])

  const handleBuildingValueChange = ({
    entityId: buildingId,
    header,
    cell,
    value,
  }) => {
    const isCustomField = !header.reserved
    const isCreatingNewValue = !cell.id

    if (isCustomField) {
      if (isCreatingNewValue) {
        createBuildingCustomField({
          surveyId,
          buildingId,
          customFieldId: header.field.id,
          newValue: value,
        })
      } else {
        updateBuildingCustomField({
          id: cell.id,
          newValue: value,
        })
      }
    }

    if (!isCustomField) {
      if (header.modelName === 'address') {
        const addressValues = value.GOOGLE_ADDRESS

        // Copying the buildings to prevent the address being updated even when the request fails
        // I think this happens because `Api.updateBuildingAddress` already handles some redux state by itself
        const beforeEditBuildings = [...surveyBuildingsRedux]

        if (addressValues.address) {
          Api.updateBuildingAddress({
            id: buildingId,
            body: {
              ...addressValues,
            },
          }).then((res) => {
            const { error } = res.data

            if (error) {
              SnackbarUtils.error(error)
              dispatch(setSurveyBuildings(beforeEditBuildings))
            } else {
              const udpatedBuildings = surveyBuildingsRedux.map((sb) => {
                if (sb.building.id === buildingId) {
                  return {
                    ...sb,
                    building: {
                      ...sb.building,
                      ...res.data,
                    },
                  }
                }
                return { ...sb }
              })
              dispatch(setSurveyBuildings(udpatedBuildings))
            }
          })
        }

        return
      }

      const updatedBuilding = {
        [header.modelName]: value,
      }

      buildingApi
        .updatePartial({
          buildingId,
          partial: updatedBuilding,
        })
        .then(([, responseBuilding]) => {
          const udpatedBuildings = surveyBuildingsRedux.map((sb) => {
            if (sb.building.id === buildingId) {
              return {
                ...sb,
                building: {
                  ...sb.building,
                  ...responseBuilding,
                },
              }
            }
            return { ...sb }
          })
          dispatch(setSurveyBuildings(udpatedBuildings))
        })
        .catch((error) => {
          console.error(error)
          SnackbarUtils.error(`Error updating ${header.displayName}`)
          dispatch(setSurveyBuildings(surveyBuildingsRedux))
        })
    }
  }

  return {
    buildingValues,
    buildingColumns,
    handleBuildingValueChange,
    isBuildingValuesLoading,
    refreshBuildingValues,
  }
}
