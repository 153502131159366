// @ts-check
import { useMemo } from 'react'

/**
 * Calculate distinct values for each column in either buildings or spaces table
 * @param {Object} params
 * @param {Array} params.columns - Column definitions (buildingColumns or spaceColumns)
 * @param {Array} params.values - Array of values (buildingValues or listingValues)
 * @param {boolean} params.loading - Whether the data is loading
 */
export const useColumnStats = ({ columns, values, loading }) => {
  return useMemo(() => {
    const stats = {}

    // Don't calculate stats until data is loaded
    if (loading) {
      return stats
    }

    columns.forEach((column) => {
      const key = column.field?.id || column.displayName
      const distinctValues = new Set()

      // Process all values from the complete dataset
      values?.forEach((value) => {
        if (value.custom_field_id === column.field?.id && value.value != null) {
          distinctValues.add(value.value)
        }
      })

      stats[key] = {
        distinctCount: distinctValues.size,
        fullDistinctValues: distinctValues,
        fullDistinctCount: distinctValues.size,
        fieldType: column.fieldDataType?.id,
        isReserved: column.reserved,
        displayName: column.displayName,
      }
    })

    return stats
  }, [columns, values, loading])
}
