// @ts-check
import React from 'react'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import { BulkEditCollection } from '~/legacy/pages/Surveys/Survey/bulk-edit-collection/bulk-edit-collection'

export function BulkEditDatabase(props) {
  return (
    <QueryParamProvider
      adapter={ReactRouter5Adapter}
      options={{ removeDefaultsFromUrl: true }}
    >
      <BulkEditCollection {...props} />
    </QueryParamProvider>
  )
}
