// @ts-check
import React from 'react'
import { ButtonBase } from '@material-ui/core'
import { FilterList } from '@material-ui/icons'
import { Typography } from '~/legacy/components/themeComponents'
import { ArrowUpIcon, ArrowDownIcon } from '~/legacy/components/svgs'
import { useSearch } from '~/legacy/components/search-bar/use-search'
import { BULK_IMPORT_CONSTANTS } from '~/legacy/utils'

export const FilterHeader = ({ children }) => (
  <h3 className="font-medium text-lg">{children}</h3>
)

export const FilterLabel = ({ label, children, ...props }) => {
  return (
    <div className="space-y-4">
      <div className="flex flex-col gap-2">
        <label className="flex mb-1 justify-between items-center">
          <Typography variant="boldTextSmall">{label}</Typography>

          {/* FIXME: Temporary way of clearing date filters */}
          {props.field?.data_type ===
          BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.DATE.id ? (
            <ButtonBase onClick={() => props?.onClear?.()}>
              <Typography color="primary" variant="boldTextSmall">
                reset filter
              </Typography>
            </ButtonBase>
          ) : null}
        </label>
        {children}
      </div>
    </div>
  )
}

export const SearchButton = ({ column, children }) => {
  const { setQuery, sortColumn, sortOrder } = useSearch()
  const isSelected = column && sortColumn === column
  const direction = isSelected ? sortOrder : 'asc'

  return (
    <label
      onClick={() => {
        if (!column) return
        const columnToSort = column || sortColumn
        let newDirection = direction

        // First click sorts asc, second click sorts desc, third click resets
        if (isSelected && sortOrder === 'asc') {
          newDirection = 'desc'
        } else if (isSelected && sortOrder === 'desc') {
          newDirection = undefined
        }
        setQuery({
          sortColumn: newDirection ? columnToSort : undefined,
          sortOrder: newDirection,
        })
      }}
      className="flex items-center gap-2 cursor-pointer w-full"
    >
      {children}
      {isSelected &&
        (direction === 'asc' ? <ArrowUpIcon /> : <ArrowDownIcon />)}
    </label>
  )
}

export const ClearFiltersButton = () => {
  const { setQuery } = useSearch()

  return (
    <button
      type="button"
      onClick={() => {
        setQuery({}, 'replace')
      }}
      className="px-4 py-2 border rounded bg-white hover:bg-gray-50"
    >
      Clear
    </button>
  )
}

export const ToggleFiltersButton = ({ showFilters, setShowFilters }) => {
  const { filterCount: activeFilterCount } = useSearch()
  const filterCountCopy =
    activeFilterCount > 0 ? (
      <>
        {activeFilterCount}
        {' '}
Filter
        {activeFilterCount > 1 ? 's' : ''}
      </>
    ) : (
      `Filters`
    )
  return (
    <div className="flex items-center">
      <button
        className="py-0 pl-2 pr-3 h-8 border border-[#E0E0E0] border-solid text-[#111111] mb-0 rounded bg-white flex items-center gap-1 cursor-pointer"
        onClick={() => setShowFilters(!showFilters)}
      >
        <FilterList />
        <Typography variant="boldText">
          {showFilters ? 'Hide Filters' : filterCountCopy}
        </Typography>
      </button>
    </div>
  )
}
