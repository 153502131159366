import Api from 'rest-fetcher-redux'
import orderBy from 'lodash/orderBy'
import { setSurveyListingsAndBuildings } from '~/legacy/store/actions/viewSurvey'

import {
  RECOMMENDED_SORT,
  UPDATED_NEW_TO_OLD,
  UPDATED_OLD_TO_NEW,
  DATE_ADDED_NEW_TO_OLD_SORT,
  DATE_ADDED_OLD_TO_NEW_SORT,
} from '~/legacy/consts'

export const sortSdpSurveyBuildings = (surveyBuildings, sortOrder) => {
  const surveyBuildingOrderMap = {}
  surveyBuildings.forEach((sb) => {
    surveyBuildingOrderMap[sb.building.id] = sb.order
  })

  switch (sortOrder) {
    default:
    case RECOMMENDED_SORT:
      return orderBy(
        surveyBuildings,
        [({ building: { id } }) => surveyBuildingOrderMap[id], 'order'],
        ['asc', 'asc']
      )
    case UPDATED_NEW_TO_OLD:
      return orderBy(
        surveyBuildings,
        ({ building: { updated_at: date } }) => new Date(date),
        'desc'
      )
    case UPDATED_OLD_TO_NEW:
      return orderBy(
        surveyBuildings,
        ({ building: { updated_at: date } }) => new Date(date),
        'asc'
      )
    case DATE_ADDED_NEW_TO_OLD_SORT:
      return orderBy(
        surveyBuildings,
        ({ building: { created_at: date } }) => new Date(date),
        'desc'
      )
    case DATE_ADDED_OLD_TO_NEW_SORT:
      return orderBy(
        surveyBuildings,
        ({ building: { created_at: date } }) => new Date(date),
        'asc'
      )
  }
}

export const sortSdpSurveyListings = (
  surveyBuildings,
  surveyListings,
  sortOrder = RECOMMENDED_SORT
) => {
  const surveyBuildingOrderMap = {}
  surveyBuildings.forEach((sb) => {
    surveyBuildingOrderMap[sb.building.id] = sb.order
  })

  switch (sortOrder) {
    default:
    case RECOMMENDED_SORT:
      return orderBy(
        surveyListings,
        [
          ({
            listing: {
              building: { id },
            },
          }) => surveyBuildingOrderMap[id],
          'order',
        ],
        ['asc', 'asc']
      )
    case UPDATED_NEW_TO_OLD:
      return orderBy(
        surveyListings,
        ({ listing: { updated_at: date } }) => new Date(date),
        'desc'
      )
    case UPDATED_OLD_TO_NEW:
      return orderBy(
        surveyListings,
        ({ listing: { updated_at: date } }) => new Date(date),
        'asc'
      )
    case DATE_ADDED_NEW_TO_OLD_SORT:
      return orderBy(
        surveyListings,
        ({ listing: { created_at: date } }) => new Date(date),
        'desc'
      )
    case DATE_ADDED_OLD_TO_NEW_SORT:
      return orderBy(
        surveyListings,
        ({ listing: { created_at: date } }) => new Date(date),
        'asc'
      )
  }
}

export const removeListingsFromSurvey = (
  surveyId,
  surveyListings,
  surveyBuildings,
  surveyListingsSetToRemove,
  dispatch,
  setSelectedSurveyListings,
  surveyBuildingIdsToDelete = null,
  onComplete = () => {}
) => {
  Api.remove_survey_listings({
    id: surveyId,
    body: {
      survey_listing_ids: [...surveyListingsSetToRemove],
      survey_building_ids_to_delete: surveyBuildingIdsToDelete,
    },
  }).then((result) => {
    if (result) {
      // Get the new set of survey listings for the survey. Keep track of all buildings
      const buildingIds = new Set()

      const newSurveyListings = surveyListings.filter((surveyListing) => {
        const shouldInclude = !surveyListingsSetToRemove.has(surveyListing.id)
        if (shouldInclude) {
          buildingIds.add(surveyListing.listing.building.id)
        }
        return shouldInclude
      })

      // Filter out a building if we just deleted its last survey listing
      const newSurveyBuildings = surveyBuildings.filter(
        (surveyBuilding) =>
          (surveyBuildingIdsToDelete === null &&
            buildingIds.has(surveyBuilding.building.id)) ||
          (surveyBuildingIdsToDelete &&
            !surveyBuildingIdsToDelete.includes(surveyBuilding.building.id))
      )

      dispatch(
        setSurveyListingsAndBuildings(newSurveyListings, newSurveyBuildings)
      )
      setSelectedSurveyListings({})
      onComplete()
    }
  })
}
