// @ts-check
import React, { useMemo, useState } from 'react'
import Fuse from 'fuse.js'
import useSWR from 'swr'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { ActionModal2 } from '~/legacy/components/modals/ActionModal2'
import { surveyApi } from '~/legacy/fetchApi'
import { SnackbarUtils } from '~/legacy/utils'

const TOP_FUSE_RESULTS = 20
const CREATE_OPTION = { name: 'Add to new survey…', isCreateOption: true }

export function AddToSurveyModal({
  open,
  onClose,
  survey,
  selectedListingIds,
}) {
  const [isCreating, setIsCreating] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [destinationSurvey, setDestinationSurvey] = useState(null)

  const { data: surveyOptions } = useSWR(
    open ? 'available_surveys' : null,
    () => surveyApi.getAvailableToAdd().then(([, response]) => response)
  )

  const fuse = useMemo(() => {
    return new Fuse(surveyOptions || [], {
      keys: [
        {
          name: 'name',
          weight: 2,
        },
        'project_name',
      ],
      includeScore: true,
      shouldSort: true,
      threshold: 0.2,
    })
  }, [surveyOptions])

  const results = useMemo(() => {
    if (!inputValue) return [CREATE_OPTION, ...(surveyOptions || [])]
    const searchResults = fuse
      .search(inputValue)
      .map((result) => result.item)
      .slice(0, TOP_FUSE_RESULTS)

    return [CREATE_OPTION, ...searchResults]
  }, [inputValue, fuse, surveyOptions])

  const onSave = () => {
    setIsLoading(true)
    surveyApi
      .copy({
        kind: survey.kind,
        surveyId: survey.id,
        destinationSurveyId: destinationSurvey?.id,
        listingIds: selectedListingIds,
        surveyName: inputValue,
      })
      .then(([, response]) => {
        console.log(response)
        SnackbarUtils.success(`Data added to survey ${response.name}`)
        onClose()
      })
      .catch((error) => {
        console.log('error', error)
        SnackbarUtils.error(
          `Error adding survey. Try again or contact support.`
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <ActionModal2
      title={isCreating ? 'Add to new survey' : 'Add to survey'}
      open={open}
      onClose={onClose}
      loading={isLoading}
      onAction={onSave}
      action={isCreating ? 'Create' : 'Add'}
    >
      <div className="mt-8 mb-10">
        {isCreating ? (
          <TextField
            label="Survey"
            variant="outlined"
            fullWidth
            autoFocus
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        ) : (
          <Autocomplete
            id="survey-search"
            ListboxProps={{ style: { maxHeight: '350px' } }}
            options={results}
            getOptionLabel={(option) => option.name}
            filterOptions={(x) => x}
            filterSelectedOptions
            inputValue={inputValue}
            onInputChange={(_, value) => {
              if (value !== CREATE_OPTION.name) {
                setInputValue(value)
              }
            }}
            value={destinationSurvey}
            onChange={(_, value) => {
              if (value.isCreateOption) {
                // setInputValue('')
                setIsCreating(true)
              } else {
                setDestinationSurvey(value)
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Survey"
                variant="outlined"
                fullWidth
                autoFocus
              />
            )}
            renderOption={(option) => {
              return (
                <div className="flex flex-col justify-start">
                  <span>{option.name}</span>
                  <Typography variant="body2" color="textSecondary">
                    {option.project_name}
                  </Typography>
                </div>
              )
            }}
          />
        )}
      </div>
    </ActionModal2>
  )
}
