import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { LEASE_DASHBOARD } from '~/legacy/consts'
import { useHelpLink } from '~/legacy/utils/hooks'
import { TextLink, Typography } from './themeComponents'

export const EncounteredError = ({ title, errorText }) => {
  const history = useHistory()
  const [helpLinkState, refreshHelpToken] = useHelpLink()
  const user = useSelector((store) => store.user)

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <div
        style={{ marginTop: '25%', display: 'flex', flexDirection: 'column' }}
      >
        <Typography variant="pageTitle" component="div">{title}</Typography>
      </div>
      <Typography variant="bodyText" component="div" style={{ marginTop: '20px' }}>
        {errorText}
      </Typography>
      <TextLink onClick={() => history.push(LEASE_DASHBOARD)} component="div">
        <Typography variant="textSmall" component="span" style={{ marginTop: '20px' }}>
          Go back Home
        </Typography>
      </TextLink>
      {user && user.loggedIn && user.id && (
        <TextLink
          onClick={() => {
            window.location = helpLinkState.link
            refreshHelpToken()
          }}
          component="div"
        >
          <Typography variant="textSmall" component="span" style={{ marginTop: '20px' }}>
            Get help from Support
          </Typography>
        </TextLink>
      )}
    </div>
  )
}

export const GeneralEncounteredError = () => {
  return (
    <EncounteredError
      title="Something Went Wrong"
      errorText="We encountered an error, please try refreshing the page."
    />
  )
}

export const PrrmissionEncounteredError = () => {
  return (
    <EncounteredError
      title="Permission Needed"
      errorText="You do not have permission to view this page."
    />
  )
}
