// @ts-check
import { makeStyles } from '@material-ui/core/styles'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { format } from 'date-fns'
import { usePostHog } from 'posthog-js/react'
import {
  AddressAutocomplete,
  BuildingTitleCard,
  SquareCheckbox,
} from '~/legacy/components'
import { Typography } from '~/legacy/components/themeComponents'
import { surveyBuildingApi } from '~/legacy/fetchApi'
import { addSurveyBuilding } from '~/legacy/store/actions/viewSurvey'
import { getViewBuildingRoute, SnackbarUtils } from '~/legacy/utils'
import { unless } from '~/libraries/styled'
import { useChanges } from '~/support/useChange'
import { useScroll } from '~/support/useScroll'
import { useWhenever } from '~/support/useWhenever'
import { useTranslation } from '~/locales/translations'
import { ActionModal2 } from '../modals/ActionModal2'

const { SURVEY_BUILDING_ALREADY_EXISTS } = surveyBuildingApi.errorCodes

export const useStyles = makeStyles({
  autocomplete: {
    marginTop: '32px',
    marginBottom: '40px',
  },
  card: {
    marginTop: '28px',
  },
  title: {
    marginTop: '32px',
  },
  items: {
    marginTop: '16px',
    marginBottom: '32px',
    maxHeight: `${66 * 4 + 36}px`,
    borderTop: '1px solid #E0E0E0',
    borderBottom: unless('bottom', '1px solid #E0E0E0'),
    overflowY: 'auto',
  },
  item: {
    height: '66px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #E0E0E0',
    gap: '12px',
  },
  checkbox: {
    color: '#111111',
    flex: 'none',
    margin: '0px',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    flex: '1',
  },
  date: {
    color: '#666666',
    whiteSpace: 'nowrap',
  },
  surveyUnavailable: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
})

export const AddBuildingToSurveyModal2 = ({
  surveyId,
  open,
  onClose,
  onSuccess = undefined,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const posthog = usePostHog()
  const user = useSelector((s) => s.user)

  const [building, setBuilding] = useState({})
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [surveyToCopyId, setSurveyToCopyId] = useState(null)
  const surveyBuildings = useSelector(
    (store) => store.pages.viewSurvey.surveyBuildings
  )

  const surveys = useMemo(() => {
    const surveys = building.surveys_with_spaces ?? []
    return surveys.filter((survey) => survey.id !== surveyId)
  }, [building, surveyId])

  useChanges(building, () => {
    setError('')
    setIsLoading(false)
  })

  useWhenever(open, () => {
    setBuilding({})
    setError('')
    setIsLoading(false)
    setIsSaving(false)
    setSurveyToCopyId(null)
  })

  const addBuildingData = (data = {}) => {
    return setBuilding((snapshot) => {
      return { ...snapshot, ...data }
    })
  }

  const close = () => {
    onClose?.()
  }

  const save = async () => {
    if (!building.address) {
      setError('Please enter an address')
      return
    }

    setIsSaving(true)

    await surveyBuildingApi
      .create({
        surveyId,
        building,
        serializerKey: 'sdp',
        copySpacesFromSurveyId: surveyToCopyId,
      })
      .then(([, responseBody]) => {
        setIsSaving(false)

        dispatch(addSurveyBuilding(responseBody.data))

        SnackbarUtils.success(t('bulkEdit.toasts.buildingAdded'))

        if (surveyBuildings.length === 0) {
          // Only capture first building added to survey
          posthog.capture('survey_journey:building_added', {
            surveyId,
            buildingId: responseBody.data.building.id,
            userId: user.id,
            userRole: user.role,
            source: 'add_building_button',
          })
        }

        if (onSuccess) {
          close()
          onSuccess(responseBody.data)
        } else {
          history.push(
            getViewBuildingRoute(surveyId, responseBody.data.building.id)
          )
        }
      })
      .catch((error) => {
        setIsSaving(false)

        if (error?.message === SURVEY_BUILDING_ALREADY_EXISTS) {
          SnackbarUtils.success('Building already added to survey.')
        } else {
          SnackbarUtils.error('Error adding building to survey.')
        }
        posthog.capture('survey_journey:error', {
          surveyId,
          userId: user.id,
          userRole: user.role,
          errorType: error.data?.code,
          errorMessage: error.message,
          source: 'add_building_button',
        })

        close()
        throw new Error(error.error_code)
      })
  }

  return (
    <ActionModal2
      open={open}
      title={building.isFuseMatch ? 'Add Existing Building' : 'Add Building'}
      action="Add to Survey"
      loading={isLoading || isSaving}
      onAction={save}
      onClose={close}
    >
      {surveys.length > 0 ? (
        <Spaces
          value={surveyToCopyId}
          onChange={setSurveyToCopyId}
          building={building}
          surveys={surveys}
        />
      ) : (
        <div className={classes.autocomplete}>
          <AddressAutocomplete
            error={!!error}
            helperText={error}
            label="Address"
            showExistingBuildings
            excludeExistingSurveyBuildings
            autoFocus
            surveyId={surveyId}
            fetchFreshBuildingData
            setAddressValues={addBuildingData}
            setBuildingValues={addBuildingData}
            overrideBuildingValues={setBuilding}
            controlledValue={building}
          />
        </div>
      )}
    </ActionModal2>
  )
}

export const Spaces = (props) => {
  const [itemsRef, setItemsRef] = useState(null)
  const classes = useStyles(useScroll(itemsRef))

  return (
    <>
      <BuildingTitleCard building={props.building} className={classes.card} />
      <Typography className={classes.title}>
        Select a survey to import building and space data from
      </Typography>
      <div ref={setItemsRef} className={classes.items}>
        {props.surveys.map((survey) => {
          return (
            <div key={survey.id} className={classes.item}>
              <div>
                <SquareCheckbox
                  className={classes.checkbox}
                  checked={props.value === survey.id}
                  onClick={() => {
                    props.onChange?.(
                      survey.id === props.value ? null : survey.id
                    )
                  }}
                />
              </div>
              <div className={classes.text}>
                <Typography variant="boldText">{survey.name}</Typography>
                <Typography variant="textSmall">
                  {survey.project?.name || 'Availability'}
                </Typography>
              </div>
              <Typography noWrap variant="textSmall" className={classes.date}>
                {`Updated ${format(Date.parse(survey.updated_at), 'MM/dd/yy')}`}
              </Typography>
            </div>
          )
        })}
      </div>
    </>
  )
}
