// @ts-check
/* ROOT */
export const LEASE_ROOT = '/'
export const PREVIEW_ROOT = `${LEASE_ROOT}preview/`

/* LOGIN */
export const LEASE_LOGIN = '/login'
export const LEASE_MAGIC_LINK = '/magic_link'
export const LEASE_RESET_PASSWORD = '/reset_password'
export const LEASE_CREATE_PASSWORD = '/create_password'

/* DASHBOARD */
export const LEASE_DASHBOARD = '/dashboard'

/* SETTINGS */
export const LEASE_SETTINGS = '/settings'

/* LISTINGS */
export const LEASE_LISTINGS = '/listings'
export const LEASE_CREATE_LISTING = '/listings/create'
export const LEASE_EDIT_LISTINGS_BY_ID = '/listings/edit/:listing_id'
export const LEASE_LISTING_WITH_ACTION = '/listings/:action/:id'
export const LEASE_VIEW_LISTINGS = '/listings/view'
export const LEASE_VIEW_LISTINGS_BY_ID = `${LEASE_VIEW_LISTINGS}/:listing_id`
export const LEASE_VIEW_LISTINGS_BY_ID_YOUR = `${LEASE_VIEW_LISTINGS}/:listing_id/your`
export const LEASE_SAVED_LISTINGS = '/listings/saved'
export const LEASE_CLIENT_LISTINGS = '/listings/client_listings/:id'
export const LEASE_SAVED_SEARCHES_LISTINGS = '/listings/searches'
export const LEASE_YOUR_LISTINGS = '/listings/your'

/* BUILDINGS */
export const VIEW_BUILDING = '/buildings/:building_id'

/* BUILDING LISTINGS */
export const LEASE_BUILDING_LISTINGS = `${VIEW_BUILDING}/listings`
export const LEASE_CREATE_BUILDING_LISTING = `${LEASE_BUILDING_LISTINGS}/create`

/* IMPORT LISTINGS / UPLOAD CSV */

/* SURVEYS */
export const LEASE_PROJECTS = '/surveys'
export const LEASE_PROJECT = '/surveys/view'
export const LEASE_PROJECT_BY_ID = '/surveys/view/:id'
export const LEASE_PROJECT_BULK_EDIT = '/surveys/bulk-edit/:id'
export const LEASE_PROJECT_WITH_ACTION = '/surveys/:action/:id'
export const LEASE_SURVEY_EXPORT = '/surveys/export/:id'
export const LEASE_SURVEY_CREATE_LISTING = '/surveys/:survey_id/listings/create'
export const LEASE_SURVEY_VIEW_LISTING =
  '/surveys/:survey_id/listings/view/:listing_id'
export const BUILDING_DETAIL_PAGE = '/surveys/:survey_id/buildings/:building_id'
export const LEASE_SURVEY_BUILDING_CREATE_LISTING =
  '/surveys/:survey_id/buildings/:building_id/listings/create'
export const LEASE_EDIT_SURVEY_LISTINGS_BY_ID =
  '/surveys/:survey_id/listings/edit/:listing_id'
export const LEASE_SURVEY_COMPARE_LISTINGS =
  '/surveys/:survey_id/listings/compare'
export const LEASE_SURVEY_COMPARE_SPACES = '/surveys/:survey_id/spaces/compare'

/* PROJECTS */
export const PROJECT_VIEW_BY_ID = '/projects/:project_id/view'
export const PROJECT_SURVEY_VIEW_BY_ID =
  '/projects/:project_id/surveys/:survey_id/view'

export const LEASE_FEEDBACK = '/feedback'
export const LEASE_ZENDESK = '/zendesk'

export const LEASE_ADMIN = '/admin'

/* FOR TENANT */

export const LEASE_MISSING_SUBDOMAIN = '/missing'

/* FOR BROKER */
export const BROKER_HOME = `${PREVIEW_ROOT}broker/home`
export const PROJECT_DETAIL = `${PREVIEW_ROOT}projects/:project_id`
