// @ts-check
import React from 'react'
import { useHistory } from 'react-router-dom'
import { MenuItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getViewBuildingRoute } from '~/legacy/utils'
import { EditIcon } from '~/legacy/components'
import { BASE_ICON_STYLES } from './ButtonUtils'
import { useTranslation } from '~/locales/translations'

const useStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
}))

export const EditBuildingMenuItem = React.forwardRef(
  ({ buildingId, surveyId, ...props }, ref) => {
    const classes = useStyles()
    const history = useHistory()
    const { t } = useTranslation()

    return (
      <MenuItem
        ref={ref}
        className={classes.menuItemRoot}
        onClick={() => history.push(getViewBuildingRoute(surveyId, buildingId))}
        {...props}
      >
        <EditIcon className={classes.icon} />
        <Typography className={classes.text}>
          {t('survey.buildingOptions.edit')}
        </Typography>
      </MenuItem>
    )
  }
)
